section.BupHome-explore {
	padding-top: $padding-y;

	button.BupButton-root {
		margin: 16px auto 0;
	}
	img.BupImage-root {
		@include video;
		border: none;
	}
	div.BupExplore-card {
		display: flex;
		border: none;
		flex-direction: column;
		gap: 16px;
		text-align: center;
		$margin: 320px;
		justify-content: space-between;

		// &:first-child {
		// 	margin-bottom: $margin;

		// 	h3.MuiTypography-h3 {
		// 		@include text($color-5);
		// 	}
		// }
		// &:last-child {
		// 	margin-top: $margin;

		// 	h3.MuiTypography-h3 {
		// 		@include text($color-7);
		// 	}
		// }
	}
	div.BupStack-root {
		display: grid;
		grid-template-columns: 1fr 1fr;
		margin-top: $padding-y;
		gap: 48px;

		h3.MuiTypography-h3 {
			@include font(30px, 500);
			margin: 32px auto 0;
			max-width: $xs * 0.9;
			text-transform: none;
		}
		span.MuiTypography-p {
			max-width: $xs * 1.25;
			margin: 0 auto;
		}
	}

	@media only screen and (max-width: $md) {
		div.BupExplore-card {
			&:first-child,
			&:last-child {
				margin: 0;
			}
		}
		h3.MuiTypography-h3 {
			@include font(26px, bold);
			max-width: $md;
		}
		div.BupStack-root {
			display: flex;
			flex-direction: column;
		}
		button {
			width: 225px;
		}
	}
	@media only screen and (max-width: $sm) {
		h3.MuiTypography-h3 {
			@include font(24px, bold);
			margin: 16px auto 0;
			max-width: 320px;
		}
		button {
			margin: 4px auto 0;
		}
	}
}
.Bup-development {
	section.BupHome-explore {
		div.BupExplore-card {
			&:first-child {
				h3.MuiTypography-h3 {
					@include text(lighten($color-3, 16%), 35%);
				}
			}
			&:last-child {
				h3.MuiTypography-h3 {
					@include text(lighten($color-6, 12%), 20%);
				}
			}
		}
	}
}
