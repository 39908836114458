@import "styles/config";

main.BupPricing-root {
  & > section.BupPricing-benefits {
    & > div {
      aspect-ratio: 3;
      border-radius: $corner;
      padding: 32px;

      h3.MuiTypography-h3 {
        font-size: 24px;
        font-weight: 500;
      }
      span.MuiTypography-p {
        font-size: 15px;
      }
      button.MuiButton-outlinedInherit {
        border-color: white;
      }
    }
  }
  & > section.BupPricing-faq {
    margin-top: 0;

    div.Bup-faq {
      h4.MuiTypography-h4 {
        font-size: 15px;
        font-weight: 500;
      }
      span.MuiTypography-p {
        font-size: 13px;
        color: #777;

        a {
          color: $tertiary;
          font-weight: 500;

          &:hover {
            text-decoration: underline;
          }
        }
      }
      i.BupIcon-root {
        display: none;
      }

      @media only screen and (max-width: $md) {
        border: 1px solid $border;
        border-radius: $corner;
        padding: 16px;

        h4.MuiTypography-h4 {
          font-size: 14px;
        }
        span.MuiTypography-p {
          display: none;
        }
        i.BupIcon-root {
          display: block;
          font-size: 18px;
          font-weight: 300;
        }
        &.Bup-expanded {
          span.MuiTypography-p {
            display: block;
          }
        }
        &:not(.Bup-expanded):hover {
          background-color: $bg-1;
        }
      }
      @media only screen and (max-width: $sm) {
        border-radius: 4px;
      }
    }
  }
}

@import "compare";
@import "features";
@import "plans";
