div.BupImage-root,
img.BupImage-root {
	@include image;
	background: $bg-4;
	border-radius: $corner;

	&.BupImage-bg {
		background-position: center;
		background-size: cover;
		border-radius: $corner;
		padding: 12px;

		&:has(.BupImage-filter) {
			div.BupImage-filter {
				border-radius: $corner;
				height: 100%;
			}
		}
	}
	&.BupImage-banner {
		$margin: 0px;
		background-position: center;
		background-size: cover;
		border-radius: 0 !important;
		border-top-left-radius: 0 !important;
		border-top-right-radius: 0 !important;
		border: none;
		padding: 0;
		margin: 0 $margin;
		width: calc(100% - #{$margin * 2});

		div.BupImage-filter {
			@include col-center;

			aspect-ratio: 2.85;
			border-radius: 0 !important;
			background: linear-gradient(rgba(black, 0.3), rgba(black, 0.4), transparent);
			color: white;
			gap: 16px;
			padding: 16px;
			width: 100%;

			h1.MuiTypography-h1 {
				font-size: 40px;
				padding-top: 32px;
				text-shadow: 0 0 8px rgba(black, 0.66);
				text-align: center;
			}
			span {
				text-transform: uppercase;

				& + h1.MuiTypography-h1 {
					padding-top: 0;
				}
			}

			@media only screen and (max-width: $xl) {
				aspect-ratio: 3;
			}
			@media only screen and (max-width: $lg) {
				aspect-ratio: 2.5;
			}
			@media only screen and (max-width: $md) {
				border-radius: 0 !important;
				aspect-ratio: 2;

				h1.MuiTypography-h1 {
					font-size: 32px;

					br {
						display: none;
					}
				}
			}
			@media only screen and (max-width: $sm) {
				aspect-ratio: 16/9;
			}
			@media only screen and (max-width: $xs) {
				aspect-ratio: 4/3;
			}
		}
		@media only screen and (max-width: $xl) {
			border-radius: 0 !important;
			margin: 0;
			width: 100%;
		}
	}
	&.BupImage-media {
		$margin: 16px;
		aspect-ratio: 2.85;
		margin-left: $margin;
		margin-right: $margin;
		width: calc(100% - #{$margin * 2});

		@media only screen and (max-width: $xl) {
			aspect-ratio: 3;
		}
		@media only screen and (max-width: $lg) {
			aspect-ratio: 2.5;
		}
		@media only screen and (max-width: $md) {
			$margin: 12px;
			aspect-ratio: 2;
			margin-left: $margin;
			margin-right: $margin;
			width: calc(100% - #{$margin * 2});
		}
	}
	&.BupImage-fill {
		position: relative;

		img,
		span {
			@include image;
			border-radius: $corner;
			height: inherit !important;
			min-height: 0 !important;
			min-width: 0 !important;
			width: inherit !important;
		}
	}
}

.Bup-development {
	.BupImage-root,
	.BupImage-lazy,
	.MuiCardMedia-root {
		background-color: $dark;
		border: 0.5px solid $dark-2 !important;
		filter: grayscale(0.1) brightness(0.9);

		&:hover {
			border-color: lighten($dark-2, 8%) !important;
		}
	}
}
