div.BupChip-root {
	&.MuiChip-filledSuccess {
		@include background($success);
		color: white;
	}
}
ul.BupChip-group {
	display: flex;
	flex-wrap: wrap;
	gap: 8px;

	li {
		i.BupIcon-root {
			font-size: 16px;
			margin-left: 8px;
		}
		div.MuiChip-root {
			margin: 0 !important;
		}
		div.MuiChip-outlined {
			&.Bup-active {
				background: rgba($primary, 0.08);
				border-color: rgba($primary, 0.32);
				color: $primary;

				i.BupIcon-root {
					color: $primary;
				}
			}
		}
	}
}
