div.BupTabs-root {
	position: relative;
	z-index: 10;

	button.MuiTab-root {
		font-size: 12px;
		text-transform: none;

		i.BupIcon-root {
			font-size: 24px;
		}
	}
	&.BupTabs-filter {
		button.MuiTab-root {
			border-radius: $corner;
			display: flex;
			flex-direction: column;
			font-size: 12px;
			font-weight: normal;
			gap: 3px;
			justify-content: center;
			min-height: 64px;
			padding: 0px 16px;

			i.BupIcon-root {
				font-size: 22px !important;
				margin: 0;
			}
		}
		span.MuiTabs-indicator {
			background: rgba($primary, 0.08);
			border-radius: $corner;
			height: 100%;

			@media only screen and (max-width: $xs) {
				height: 2px;
				background: rgba($primary, 0.72);
			}
		}
		& + button.BupButton-root {
			border-color: $border;
			border-radius: $radius;
		}
	}
	div.MuiTabs-scrollButtons {
		background: white;
		color: black;
		height: 100%;
		margin: 0;
		opacity: 0.9;
		padding: 4px;
		position: absolute;
		width: 24px;
		z-index: 20;

		&.Mui-disabled {
			opacity: 0;
			width: 0;
		}
		&:hover {
			background: $bg-2;
		}
		&:last-child {
			right: 0;
		}
		@media only screen and (max-width: $xs) {
			border-radius: 50%;
			border: 1px solid $border;
			height: 32px;
			width: 32px;
			margin: 16px 0;
		}
	}
	&.BupTabs-nav {
		border-bottom: 1.5px solid $border;
		min-height: 40px;

		@media only screen and (max-width: $sm) {
			padding: 0;
		}
		@media only screen and (max-width: $xs) {
			margin: 16px auto 0;
			width: fit-content;
			border-radius: 0 !important;
			box-shadow: none !important;
		}

		button.MuiTab-root {
			border-radius: 0;
			font-size: 13.5px;
			font-weight: 500;
			min-height: 40px;
			padding: 6px 16px;

			i {
				display: none;
			}
			@media only screen and (max-width: $xs) {
				font-size: 12.5px;
			}
		}
	}
}
article.BupTabs-panel {
	@media only screen and (max-width: $sm) {
		background: transparent;
		padding: 0;
	}
}
div.MuiPaper-root {
	&:has(div.BupTabs-root) {
		background: transparent;
		box-shadow: none;
		padding: 0 0 16px;

		@media only screen and (max-width: $xs) {
			box-shadow: none;
			border-bottom: none;
			background: transparent;
			margin: 0 !important;
			padding: 4px 8px 0px;
		}
	}
}
div.BupTabs-list {
	align-items: center;
	display: flex;
	justify-content: space-between;
	flex-direction: row !important;
	padding: 2px 16px 0;

	h6.MuiTypography-h6 {
		font-size: 15px;
		font-weight: bold;
	}
	button.BupButton-root {
		box-shadow: none;
		border-radius: 18px;
		margin-left: 4px;

		&:not(.Bup-active) {
			background: transparent;
			color: #777;

			&:hover {
				background: $bg-3;
			}
		}
	}
	@media only screen and (max-width: $sm) {
		padding: 2px 12px 0;
	}
}
