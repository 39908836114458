section.BupPricing-plans {
  center.BupPricing-info {
    text-align: center;

    a {
      color: $tertiary;
      font-weight: 400;
      text-decoration: underline;
    }
    div:has(small) {
      margin-left: auto;
      margin-right: auto;
      max-width: 100%;
      width: calc(#{$sm}/ 2 + #{$xs}/ 2);

      small {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
  div.BupPricing-plans {
    align-items: stretch;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr;
    gap: 24px;
    margin: 75px auto 0;

    & > div {
      border: 1.5px solid $border;
      border-radius: $corner;

      &:has(.Bup-popular) {
        background:
          linear-gradient(90deg, $tertiary 4%, rgb(66, 133, 244) 0) top/100% 34% no-repeat,
          linear-gradient(90deg, $secondary 50%, rgb(66, 133, 244) 0) top/100% 82% no-repeat,
          linear-gradient(90deg, $secondary 10%, $dark 0) top/100% 100%;
        border-color: transparent;
        border: none;
        padding: 0;

        & > div {
          padding: 16px 16px 32px;
          height: calc(100% - 8px);
        }
      }
      & > div {
        border-radius: $corner;
        margin: 4px;
        padding: 64px 16px 32px;
        background: white;
      }
    }
    button {
      margin: 32px auto 0;
    }
    span {
      text-align: center;

      &.Bup-popular {
        @include background(lighten($secondary, 8%));
        width: fit-content;
        margin: 0 auto;
        font-size: 11px;
        color: white;
        padding: 2px 16px;
        border-radius: 16px;
        margin-bottom: 32px;
      }
      &.BupPlan-name {
        font-size: 30px;
        font-weight: 400;
      }
      &.BupPlan-price {
        font-size: 18px;
        margin-top: 12px;
      }
      &.BupPlan-periodicity {
        font-size: 12.5px;
        margin-top: 4px;
      }
      &.MuiTypography-p {
        font-size: 14px;
        font-weight: 300;
        margin: 32px auto 0;
        max-width: 80%;
      }
    }
    hr.MuiDivider-root {
      margin: 32px 0 16px !important;
    }
    ul {
      padding: 0 8px;

      li {
        font-size: 13px;
        width: 90%;

        &:first-child {
          font-size: 14px;
          font-weight: 400;
          margin-bottom: 8px;
        }
        &:has(i) {
          font-weight: 300;
          display: flex;
          justify-content: row;
          align-items: center;
          gap: 16px;

          i {
            color: $primary;
            font-size: 18px;
          }
          &:not(:last-child) {
            margin-bottom: 4px;
          }
        }
      }
    }
    @media only screen and (max-width: $lg) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media only screen and (max-width: $md) {
      grid-template-columns: 1fr;

      & > div {
        max-width: calc(100% - 24px);
        min-width: 0;
        margin: 0 auto;
        width: $xs;
      }
    }
    @media only screen and (max-width: $xs) {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      gap: 24px;

      & > div {
        margin: 0;
        padding: 0;
        min-width: 100% !important;

        & > div {
          padding: 32px 8px !important;
        }
      }
    }
  }
  ul.BupPricing-periodicity {
    @include row-center;
    margin: 75px auto 0;

    button.BupButton-root {
      align-items: center;
      border-radius: 4px;
      border-width: 1.5px;
      display: flex;
      flex-direction: column;
      gap: 4px;
      height: 48px;
      width: 180px;

      &:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        &:not(.Bup-active) {
          border-right: none;
        }
      }
      &:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        &:not(.Bup-active) {
          border-left: none;
        }
      }
      a {
        color: $primary;
      }
      b {
        line-height: 13px;
      }
      small {
        font-weight: 400;
        line-height: 13px;
      }
      &:not(.Bup-active) {
        border-color: $border;
      }
    }
  }
}
.Bup-development {
  section.BupPricing-plans {
    div.BupPricing-plans > div {
      border-color: $dark-2 !important;

      div.BupPricing-filter {
        background-color: $dark-0;

        i {
          color: $tertiary;
        }
      }
    }
    ul.BupPricing-periodicity {
      button:not(.Bup-active) {
        border-color: $dark-2 !important;
      }
    }
  }
}
