div.BupAvatar-root {
	svg.MuiAvatar-fallback {
		fill: $bg-7;
	}
}

div.BupAvatar-group {
	height: 36px;
	width: fit-content;

	hr.MuiDivider-vertical {
		height: 16px;
	}
	span.MuiTypography-span {
		color: rgba(black, 0.5);
		font-size: 12px;
		white-space: nowrap;
	}
	svg.BupLogo-root {
		height: 32px;
		min-width: 32px;
		border: 2px solid transparent;
	}
	span.MuiSkeleton-root {
		&.BupSkeleton-avatar {
			height: 32px;
			min-width: 32px;
		}
		&.BupSkeleton-member {
			min-width: 240px;
		}
	}
}
