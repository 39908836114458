div.BupPolicies-nav {
  background-color: $bg-2;
  border: 1px solid $border;
  border-radius: 8px;
  box-shadow: none;
  padding: 16px 8px 12px;

  &.Bup-sticky {
    position: fixed;
    top: 0;
    margin-top: 132px;
    width: 375px;

    @media only screen and (max-width: $lg) {
      position: relative;
      margin: 0;
      width: auto;
    }
  }
  nav > span {
    display: block;
    font-weight: 500;
    padding: 0 20px 4px;
    font-size: 18px;
  }
  ul {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0;
    padding: 4px 0;
    list-style: none;
    gap: 2px;

    li {
      align-items: center;
      border-radius: 20px;
      cursor: pointer;
      display: flex;
      font-size: 13.5px;
      font-weight: 400;
      justify-content: space-between;
      line-height: 24px;
      padding: 8px 12px 8px 20px;

      i {
        font-size: 20px !important;
        font-weight: 400;
        opacity: 0.5;
      }
      &:hover {
        background-color: $bg-3;

        i {
          opacity: 1;
        }
      }
      &.Bup-active {
        background-color: rgba($tertiary, 0.075);
        font-weight: 500;

        i {
          display: none;
        }
      }
    }
  }
}
