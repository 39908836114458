svg.BupLogo-root {
  max-height: 128px;
  max-width: 128px;

  .BupLogo-primary {
    fill: darken($tertiary, 2%);
  }
  .BupLogo-secondary {
    fill: lighten($tertiary, 8%);
  }
  &.BupLogo-premium {
    .BupLogo-primary {
      fill: darken($golden, 2%);
    }
    .BupLogo-secondary {
      fill: lighten($golden, 8%);
    }
  }
  &.BupLogo-small {
    height: 16px;
    width: 16px;
  }
  &.BupLogo-medium {
    height: 32px;
    width: 32px;
  }
  &.BupLogo-large {
    height: 44px;
    width: 44px;
  }
  &.BupLogo-circle {
    background: $golden;
    border-radius: 16px;
    padding: 2px;

    * {
      fill: white !important;
    }
  }
  &.BupLogo-square {
    background: $golden;
    border-radius: 2px;
    padding: 2px;

    * {
      fill: white !important;
    }
  }
}
.BupLogo-span {
  @include font(21px, 300);
  color: $tertiary;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.5;
  user-select: none;
}
