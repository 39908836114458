@import "styles/config";

main.BupBusiness-root {
  & > section.BupBusiness-retired {
    div.BupBusiness-what {
      background-color: $bg-2;
      border-radius: $corner;
      padding: 75px 120px;

      span.MuiTypography-p {
        font-size: 24px;
        line-height: 1.33;
        max-width: $md;

        b {
          color: lighten($tertiary, 10%);
          font-weight: 400;
        }
      }
      @media only screen and (max-width: $lg) {
        padding: 75px;
      }
      @media only screen and (max-width: $md) {
        padding: 48px 32px;

        span.MuiTypography-p {
          font-size: 20px;
        }
      }
      @media only screen and (max-width: $sm) {
        padding: 32px 16px;

        span.MuiTypography-p {
          font-size: 18px;
        }
      }
    }
    div.BupBusiness-pics {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 12px;

      div,
      img {
        background-color: $dark;
        border-radius: $corner;
        @include video;
      }
      @media only screen and (max-width: $md) {
        grid-template-columns: repeat(2, 1fr);
        gap: 8px;
      }
    }
  }
  & > section.BupBusiness-stats {
    margin-top: 0;

    div.BupBusiness-data {
      display: grid;
      grid-template-columns: repeat(5, 1fr);

      div {
        align-items: center;
        display: flex;
        text-align: center;
      }
      h4.MuiTypography-h4 {
        font-size: 32px;
      }
      span.MuiTypography-span {
        font-size: 15px;
      }
      @media only screen and (max-width: $lg) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 16px 32px;
      }
      @media only screen and (max-width: $md) {
        h4.MuiTypography-h4 {
          font-size: 28px;
        }
        span.MuiTypography-span {
          font-size: 14px;
        }
      }
      @media only screen and (max-width: $sm) {
        flex-direction: column;
        gap: 4px;

        div {
          background-color: $bg-2;
          border: 1px solid $bg-3;
          border-radius: $corner;
          padding: 12px;
        }
      }
    }
  }
  & > section.BupBusiness-features {
    ul.BupBusiness-list {
      li.BupBusiness-item {
        display: grid;
        gap: 40px;
        grid-template-columns: 1fr 1fr;

        img {
          @include video;
          background-color: $dark;
          border-radius: $corner;
          width: 100%;
        }
        h3.MuiTypography-h3 {
          margin-bottom: 24px;
        }
        span.MuiTypography-span {
          display: block;
          width: 90%;

          & + span {
            margin-top: 16px;
          }
        }
        @media only screen and (max-width: $lg) {
          gap: 24px;

          h3.MuiTypography-h3 {
            font-size: 28px;
            margin-bottom: 16px;
          }
          span.MuiTypography-span {
            font-size: 15px;
            width: 95%;
          }
        }
        @media only screen and (max-width: $md) {
          grid-template-columns: 1fr;

          img {
            order: 0;
          }
          div {
            order: 1;
          }
        }
        @media only screen and (max-width: $sm) {
          h3.MuiTypography-h3 {
            font-size: 24px;
            font-weight: 400;
            text-align: left;
          }
          span.MuiTypography-span {
            width: 100%;
          }
        }
      }
    }
    div.BupBusiness-grid {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 12px;

      & > div {
        display: flex;
        background-color: $bg-2;
        border-radius: $corner;

        & > div {
          padding: 0 16px 16px;

          h4 {
            font-size: 17px;
            font-weight: 500;
            margin-bottom: 12px;
          }
          span {
            font-size: 15px;
            font-weight: 300;
          }
        }
        & > img {
          @include video;
          border-radius: inherit;
          background-color: $bg-6;
          object-fit: cover;
          object-position: center;
        }
      }
      @media only screen and (max-width: $md) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media only screen and (max-width: $sm) {
        grid-template-columns: 1fr;

        & > div {
          & > div {
            h4 {
              font-size: 16px;
            }
            span {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  & > section.BupBusiness-testimonials {
    div.BupBusiness-slider {
      display: grid;
      grid-template-columns: 3fr 2fr;
      gap: 40px;

      & > img {
        @include video;
        background-color: $dark;
        border-radius: $corner;
        width: 100%;
      }
      & > div {
        padding-right: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        img {
          @include circle;
          background-color: $bg-2;
          height: 80px;
          width: 80px;
        }
      }
      span.MuiTypography-p {
        font-size: 18px;
        line-height: 1.33;
        opacity: 0.66;
      }
      i.BupIcon-root {
        color: $primary;
      }
    }
  }
  & > section.BupBusiness-brands {
    div.BupBusiness-grid {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      gap: 50px 100px;

      img {
        height: 30px;
        width: auto;
      }
      @media only screen and (max-width: $xl) {
        gap: 50px 75px;
      }
      @media only screen and (max-width: $lg) {
        gap: 50px 48px;
      }
      @media only screen and (max-width: $sm) {
        gap: 32px 48px;
      }
    }
  }
}
