.BupSpacing-1 {
  gap: 4px;
}
.BupSpacing-2 {
  gap: 8px;
}
.BupSpacing-3 {
  gap: 12px;
}
.BupSpacing-4 {
  gap: 16px;
}
.BupSpacing-5 {
  gap: 20px;
}
.BupSpacing-6 {
  gap: 24px;
}
.BupSpacing-7 {
  gap: 28px;
}
.BupSpacing-8 {
  gap: 32px;
}
.BupSpacing-9 {
  gap: 36px;
}
.BupSpacing-10 {
  gap: 40px;
}
.BupSpacing-11 {
  gap: 44px;
}
.BupSpacing-12 {
  gap: 48px;
}
