div.BupCard-root {
	&.BupCard-suggest {
		background: transparent;
		border-radius: $radius !important;
		box-shadow: none;
		display: grid;
		grid-template-columns: auto 1fr;
		gap: 12px;
		padding-bottom: 4px;

		div.MuiCardMedia-root {
			@include square;
			border-radius: $radius;
			height: 64px;
		}
		div.MuiCardActions-root {
			align-items: flex-start;
			padding: 0;

			i.BupIcon-root {
				font-size: 18px !important;
			}
		}
		div.MuiCardContent-root {
			color: $text;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			padding-bottom: 0;
			gap: 2px;

			div.MuiTypography-h6 {
				@include clamp(1);
				font-size: 18px;
				font-weight: 400;
				line-height: 22px;
				margin: 0;

				b {
					font-weight: 500;
				}
			}
			span.MuiTypography-span {
				@include clamp(1);
				font-size: 12.5px;
				font-weight: normal;
				margin-bottom: 2px;
				opacity: 1;
			}
			p.MuiTypography-body2 {
				@include clamp(1);
				font-size: 13.5px;
			}
		}
		span.MuiSkeleton-root {
			&.BupSkeleton-image {
				@include video;
				border-radius: $corner;
				height: 68px;
			}
			&.BupSkeleton-span {
				height: 12.5px;
			}
			&.BupSkeleton-h6 {
				height: 22px;
			}
			&.BupSkeleton-p {
				height: 13.5px;
			}
			&.BupSkeleton-avatar {
				border-radius: 50%;
				height: 54px;
				width: 54px;
			}
			&.BupSkeleton-icon {
				height: 18px;
				width: 18px;
			}
		}
		&:has(.BupAvatar-root) {
			$size: 48px;
			align-items: flex-start;
			background: $bg-0;
			border: 1px solid $bg-0;
			grid-template-columns: $size 1fr;
			grid-gap: 12px;
			padding: 0px;

			div.MuiCardMedia-root {
				@include row-center;
				border-radius: 50%;
				height: $size;
				margin: 0;
				min-width: $size;
				padding: 0;
				width: $size;

				.BupAvatar-root {
					height: inherit;
					width: inherit;
				}
			}
			div.MuiCardContent-root {
				div.MuiTypography-h6 {
					color: black;
					font-size: 16px !important;

					b {
						align-items: center;
						display: flex;
						gap: 8px;

						svg.BupLogo-root {
							height: 16px;
							width: 16px;
						}
					}
				}
				p.MuiTypography-body1 {
					font-size: 13.5px;
					margin: 0;
					color: #666;
				}
			}
			div.MuiCardActions-root {
				button.BupButton-root,
				span.BupSkeleton-button {
					padding: 3px 12px;
				}
				button.BupButton-root {
					border-color: #bbb;
				}
			}
			&:has(.MuiAvatar-rounded) {
				border-radius: $radius;

				div.BupCard-actions {
					flex-direction: row;
					justify-content: space-between;

					button.BupButton-root {
						border-color: rgba($primary, 0.32);
					}
				}
			}
		}
		@media only screen and (max-width: $xs) {
			grid-template-columns: 64px 1fr !important;
			grid-gap: 12px !important;
			padding: 0;

			div.BupCard-actions {
				flex-direction: row;
				justify-content: space-between;
			}
			div.MuiCardMedia-root {
				aspect-ratio: auto;
				height: 64px !important;
				min-width: 64px !important;
				width: 64px !important;
			}
			div.MuiCardActions-root {
				margin: 0;
			}
			&:has(.BupAvatar-root) {
				grid-template-columns: 44px 1fr !important;

				div.MuiCardMedia-root {
					aspect-ratio: auto;
					height: 44px !important;
					min-width: 44px !important;
					width: 44px !important;
				}
			}
		}
	}
}
