div.BupBrowser-root {
  background: $background;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  max-width: $screen;
  min-height: 100vh;
  overflow-x: hidden;

  & > header,
  & > footer,
  & > nav,
  & > main {
    @include padding;

    &.palette {
      display: grid;
      grid-template-columns: repeat(10, 1fr);
      gap: 1px;

      div {
        aspect-ratio: 1;
        background: $danger;

        @for $i from 0 through 9 {
          &.bg-#{$i} {
            background-color: darken($bg-0, $i * 2%);
          }
        }

        &.color-0 {
          background-color: $color-0;
        }
        &.color-1 {
          background-color: $color-1;
        }
        &.color-2 {
          background-color: $color-2;
        }
        &.color-3 {
          background-color: $color-3;
        }
        &.color-4 {
          background-color: $color-4;
        }
        &.color-5 {
          background-color: $color-5;
        }
        &.color-6 {
          background-color: $color-6;
        }
        &.color-7 {
          background-color: $color-7;
        }
        &.color-8 {
          background-color: $color-8;
        }
        &.color-9 {
          background-color: $color-9;
        }
      }
    }
    &.error {
      @include row-center;
      height: 100vh;
      gap: 15px;

      svg {
        height: 90px;
      }
      span.logo {
        @include font(52.5px, bold);
      }
      span.slogan {
        @include font(11px);
        color: $color-7;
        display: block;
        font-weight: bold;
        letter-spacing: 5.8px;
        margin-left: 3px;
        text-transform: uppercase;
      }
    }
  }
  & > main {
    h2.MuiTypography-h2 {
      @include font(36px, 500);
      max-width: $md;
      margin: 0 auto;
      text-align: center;

      b {
        color: $primary;
        font-weight: 500;
      }

      @media only screen and (max-width: $sm) {
        @include font(28px, 500);
      }
    }
    span.MuiTypography-p {
      @include font(16px);
    }
    img.MuiImage-root {
      @include background($dark);
      @include video;
    }
    .MuiTypography-root {
      font-family: $font-family;
    }
  }
  & > footer,
  & > nav {
    padding-bottom: 0;
    padding-top: 0;
  }
  & > main {
    padding: 0;

    & > section {
      @include padding;

      &.BupDivider-bottom {
        border-bottom: 1px solid $border;
        padding-bottom: 100px;
      }
    }
  }
  hr.MuiDivider-root {
    margin: $padding-y 0 !important;
  }
  &:has(main.BupLoading-root) {
    & > header,
    & > footer,
    & > nav {
      display: none;
    }
  }
  &:has(section.BupLoading-section) {
    & > footer img {
      display: none;
    }
  }
}

.Bup-development div.BupBrowser-root {
  & > header,
  & > footer,
  & > nav,
  & > main {
    background-color: $dark-0;
    color: white;

    svg path.curve {
      fill: $dark-0;
    }
    &.Bup-fixed,
    .BupImage-root {
      border-color: $dark-1;
    }
    h3.MuiTypography-h3,
    span.MuiTypography-p {
      color: rgba($background, 0.9) !important;
    }
    hr.MuiDivider-root {
      border-color: $dark-2 !important;
    }
  }
}
