section.BupHome-discover {
	padding-bottom: 0 !important;
	text-align: center;

	$height: calc((min(100vw, $screen) - 2 * $padding-x - 120px) / 3);
	$premium: #e2b776;
	$caption: 150px;

	div {
		&.BupDiscover-service {
			display: flex;
			flex-direction: column;
			gap: 16px;
			padding: 0 48px;

			span.BupDiscover-points {
				@include row-center;
				@include font(14px, 500);

				background-color: lighten($premium, 6%);
				color: darken($premium, 44%);
				border: 1px solid darken($premium, 6%);
				border-radius: $radius;
				gap: 5px;
				padding: 3px 10px;
				padding-right: 13px;
				user-select: none;
				width: fit-content;

				i {
					font-size: inherit;
				}
				&.BupPoints-events {
					background-color: #eee;
					border: 1px solid #ccc;
					margin-top: 6px;
				}
			}
			span.MuiTypography-p {
				@include font(16px, 300);
				color: #444;
			}
		}
		&.BupDiscover-slider {
			margin: $padding-y 0;
			height: calc($height + $caption);

			span.BupDiscover-control {
				@include col-center;
				position: absolute;
				height: $height;
				width: 70px;
				z-index: 1;

				button.BupButton-icon {
					border: 1px solid $bg-6;
					height: 36px;
					width: 36px;
					text-align: center;

					&.Bup-disabled {
						opacity: 0.25;
					}
				}
				&:first-of-type {
					left: 50px;
				}
				&:last-of-type {
					right: 50px;
				}
			}
		}
		&.BupDiscover-carousel {
			height: calc($height + $caption);
			margin: 0 auto;
			overflow: hidden;
			position: relative;
			width: calc(100% - 80px);

			ol {
				display: flex;
				position: absolute;
				list-style: none;
				margin: 0;
				height: inherit;
				overflow: hidden;
				padding: 0;

				li {
					display: inline-block;
					height: inherit;
					margin: 0 5px;

					&:first-child {
						margin-left: 0;
					}
					&:last-child {
						margin-right: 0;
					}

					div {
						&.BupDiscover-caption {
							@include font(15px, 300);
							height: $caption;
							padding: 15px 5px 0;
						}
						&.BupDiscover-filter {
							background: linear-gradient(180deg, transparent 60%, rgba(black, 0.4) 100%);
							border-radius: $corner;
							display: flex;
							flex-direction: column;
							justify-content: flex-end;
							padding: 30px;
							height: 100%;
						}
						&.BupDiscover-image {
							aspect-ratio: 3 / 4;
							background-position: center;
							background-size: cover;
							border-radius: $corner;
							height: $height;
						}
						span.MuiTypography-span {
							@include text($color-1);
							font-weight: 600;

							&.Bup-soon {
								$color: lighten($golden, 25%);
								@include text($color);
							}
						}
					}
					span.MuiTypography-p {
						font-size: 15px;
						font-weight: 300;
					}
				}
			}
		}
		&.BupDiscover-image {
			img.BupImage-root {
				@include video;
				margin: 0 auto;
				display: block;
			}
		}
	}
	main {
		display: grid;
		grid-template-columns: 1fr 1fr;
		margin-top: $padding-y;
		text-align: left;
		gap: 45px;
	}
	h3.MuiTypography-h3 {
		@include font(27px, 500);

		b {
			color: $color-5;
		}
	}
	h4.MuiTypography-h4 {
		@include font(18px, 500);
		color: white;
		margin-bottom: 3px;
		text-shadow: 0px 5px 5px rgba(black, 0.5);
	}
	ul {
		display: flex;
		gap: 30px;
		justify-content: center;
		margin-top: 30px;

		li {
			@include col-center;
			gap: 1px;

			span.MuiTypography-p {
				font-size: 14px !important;
				font-weight: 300;
				text-align: center;
				max-width: 120px;

				& + p {
					@include font(10.5px, 500);
					color: $color-7;
				}
			}
			img {
				@include circle;
				background: linear-gradient(180deg, $bg-5 0%, transparent 100%);
				border: 2px solid $color-7;
				height: 120px;
				min-width: 120px;
				margin-bottom: 15px;
			}
		}
	}
	p.Bup-disclaimer {
		@include font(14px);
		padding: 16px 16px 0;
	}
	@media only screen and (max-width: $xl) {
		$caption: 130px;
		$height: calc((100vw - 120px) * 4 / 9);

		div {
			&.BupDiscover-service {
				padding: 0;
				order: 1;
				text-align: center;

				span.BupDiscover-points {
					margin: 0 auto;
				}
			}
			&.BupDiscover-slider {
				height: calc($height + $caption);

				span.BupDiscover-control {
					height: $height;

					&:first-of-type {
						left: 10px;
						width: 36px;
					}
					&:last-of-type {
						right: 10px;
						width: 36px;
					}
				}
			}
			&.BupDiscover-carousel {
				height: calc($height + $caption);

				ol li div {
					width: calc((100vw - 130px) / 3);

					&.BupDiscover-caption {
						@include font(14px);
					}
					&.BupDiscover-image {
						height: $height !important;
					}
				}
			}
		}
		h3.MuiTypography-h3 {
			margin: 0 auto;
			max-width: $sm;
		}
		main {
			display: flex;
			flex-direction: column;
		}
		p.Bup-disclaimer {
			display: none;
			text-align: center;
		}
	}
	@media only screen and (max-width: $md) {
		$caption: 130px;
		$height: calc((100vw - 120px) * 2 / 3);

		h3.MuiTypography-root {
			@include text($color-5);
			@include font(26px, 500);
		}
		div {
			&.BupDiscover-service {
				span.BupDiscover-points {
					@include background($bg-0);
					color: black;
					border: 1px solid $bg-5;
				}
			}
			&.BupDiscover-slider {
				height: calc($height + $caption);

				span.BupDiscover-control {
					height: $height;
				}
			}
			&.BupDiscover-carousel {
				height: calc($height + $caption);

				ol li div {
					width: calc((100vw - 120px) / 2);

					&.BupDiscover-image {
						height: $height !important;
					}
				}
			}
		}
	}
	@media only screen and (max-width: $sm) {
		$caption: 120px;
		$height: calc(100vw - 100px);

		div {
			&.BupDiscover-slider {
				height: calc($height + $caption);

				span.BupDiscover-control {
					height: $height;
				}
			}
			&.BupDiscover-carousel {
				height: calc($height + $caption);

				ol li div {
					width: calc(100vw - 100px);

					&.BupDiscover-image {
						height: $height !important;
					}
				}
			}
		}
		h3.MuiTypography-h3 {
			@include font(24px, 500);
		}
		main {
			gap: 30px;
		}
		ul {
			margin-top: 15px;
			gap: 15px;

			li img {
				height: 100px !important;
				min-width: 100px;
				width: 100px !important;
			}
		}
	}
	@media only screen and (max-width: $xs) {
		$caption: 120px;
		$height: calc(100vw - 20px);

		div {
			&.BupDiscover-slider {
				height: calc($height + $caption);

				span.BupDiscover-control {
					height: $height;

					&:first-of-type {
						left: 15px;
					}
					&:last-of-type {
						right: 15px;
					}
					button.BupButton-icon {
						background: rgba(white, 0.5);

						&.Bup-disabled,
						&:disabled {
							opacity: 0 !important;
						}
					}
				}
			}
			&.BupDiscover-carousel {
				height: calc($height + $caption);
				width: 100%;

				ol li div {
					width: calc(100vw - 20px);

					&.BupDiscover-image {
						height: $height !important;
					}
				}
			}
		}
		ul {
			li img {
				height: 90px !important;
				min-width: 90px;
				width: 90px !important;
			}
		}
	}
}
.Bup-development {
	section.BupHome-discover {
		div.BupDiscover-slider {
			span.BupDiscover-control {
				button.BupButton-icon {
					border-color: $dark-2;
					color: $bg-4;
				}
			}
		}
	}
}
