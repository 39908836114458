nav.BupBrowser-nav {
  align-items: center;
  background: transparent !important;
  border-bottom: 1.5px solid transparent;
  display: flex;
  height: 1.5 * $navbar-y;
  justify-content: space-between;
  left: 0;
  padding: 0px 78px;
  position: fixed;
  top: 0;
  transition: all 0.2s linear;
  width: 100%;
  z-index: 99;

  & > div {
    width: 240px;

    button {
      align-items: center;
      display: flex;
      gap: 12px;
    }
  }
  span.BupLogo-span {
    @include font(28px, 500);
    @include logo($tertiary);
    margin: 0;
  }
  svg.BupBrowser-logo {
    display: inline;
    height: 40px;
    width: 40px;
  }
  button.BupButton-root {
    font-size: 14px;
  }
  .BrowserMobile-nav {
    display: none;
  }
  ul {
    align-items: center;
    display: flex;
    gap: 32px;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;
    width: $xs;

    .BupLink-nav {
      cursor: pointer;
      font-size: 14px;
      font-weight: 400;

      &.Bup-active {
        color: $tertiary;
        font-weight: 500;
      }
      &:not(.Bup-active) {
        &:hover {
          font-weight: 400;
        }
      }
    }
  }

  &:not(.Bup-home):not(.Bup-fixed) {
    color: white;

    ul .BupLink-nav {
      color: white;

      &.Bup-active {
        color: lighten($tertiary, 25%) !important;
        text-shadow: 1px 1px 1px rgba(black, 0.56);
      }
    }
    span.BupLogo-span {
      text-shadow: 1px 1px 1px rgba(white, 0.04);
    }
  }
  &.Bup-fixed {
    background: $background !important;
    border-color: $bg-4;
    height: $navbar-y;
    color: black !important;
  }
  @media only screen and (max-width: $lg) {
    padding: 0 16px !important;

    ul {
      gap: 24px;

      .BupLink-nav {
        font-size: 13.5px;
      }
    }
  }
  @media only screen and (max-width: $md) {
    padding: 0 16px !important;

    ul {
      display: none;
    }
  }
  @media only screen and (max-width: $sm) {
    padding: 0 12px !important;

    div {
      &:last-child {
        gap: 0;

        button.BupButton-root {
          &:first-child {
            background-color: $dark;
            color: white;

            &:hover {
              background-color: darken($dark, 4%);
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: $xs) {
    height: 1.2 * $navbar-y;

    .BupBrowser-login {
      display: none;
    }
    .BrowserMobile-nav {
      display: flex;
      justify-content: flex-end;

      button.BupButton-root {
        background-color: transparent !important;
        color: inherit !important;
      }
      i.BupIcon-root {
        font-size: 28px;
      }
    }
  }
}
.Bup-development {
  nav.BupBrowser-nav {
    ul *:not(.Bup-active) {
      color: white !important;
    }
    &.Bup-fixed {
      background-color: $dark-0 !important;
    }
    span.BupLogo-span {
      @include logo(lighten($tertiary, 8%), 10%);
    }
    button.BupButton-inherit {
      background-color: transparent;
      color: white;

      &:hover {
        background-color: rgba(white, 0.12);
      }
    }
  }
}
