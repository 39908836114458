@import "styles/config";

main.BupError-root {
  @include center;
  height: calc(100vh - #{$navbar-y} - 225px);
  gap: $gap;
  padding-bottom: $navbar-y;
  background-color: red;
  flex-direction: column;

  h1.MuiTypography-h1 {
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 40px;
    font-weight: 400;
    gap: $gap;
    margin-top: 200px;

    b {
      color: $secondary;
      font-weight: 300;
    }
  }
  hr.MuiDivider-root {
    height: 44px;
    margin: 0 !important;
  }
  span.MuiTypography-p {
    font-size: 15px !important;
    font-weight: 300;
    text-align: center;
  }
  @media only screen and (max-width: $md) {
    h1.MuiTypography-h1 {
      font-size: 36px;
    }
  }
  @media only screen and (max-width: $sm) {
    padding: 0 12px !important;

    h1.MuiTypography-h1 {
      font-size: 28px;
      flex-direction: column;
      gap: 12px;
      text-align: center;
      height: fit-content;
      margin-top: 150px;
      width: 100%;

      b {
        font-size: 36px;
        font-weight: 400;
      }
    }
    span.MuiTypography-p {
      font-size: 13.5px !important;
    }
    hr.MuiDivider-root {
      display: none;
    }
  }
  @media only screen and (max-width: $xs) {
    h1.MuiTypography-h1 {
      gap: 8px;
    }
    span.MuiTypography-p {
      font-size: 13.5px !important;
      max-width: 300px;
    }
  }
}
