$primary: #0047b8;
$secondary: #1568a7;
$tertiary: #0095f6;
$premium: #f7c77d;

$bg-0: white;
$bg-1: darken($bg-0, 2%);
$bg-2: darken($bg-0, 4%);
$bg-3: darken($bg-0, 6%);
$bg-4: darken($bg-0, 8%);
$bg-5: darken($bg-0, 10%);
$bg-6: darken($bg-0, 12%);
$bg-7: #767676;
$bg-8: #545454;
$bg-9: #323232;

$color-0: #f2f8f9;
$color-2: #71e1ff;
$color-4: #4dcfff;
$color-6: #00a7e5;
$color-8: #08436b;
$color-1: mix($color-0, $color-2);
$color-3: mix($color-2, $color-4);
$color-5: mix($color-4, $color-6);
$color-7: mix($color-6, $color-8);
$color-9: darken($color-8, 5%);

$type-0: #7bc6f0;
$type-1: #f9be0a;
$type-2: #f4705e;
$type-3: #1568a7;

$active: $color-6;
$admin: #0047b8;
$background: $bg-0;
$border: rgba($bg-6, 0.8);
$bronze: #cd7f32;
$danger: #d32f2f;
$dark: $color-8;
$diamond: #414161;
$golden: #daa520;
$invert: #101010;
$light: rgba($bg-6, 0.2);
$silver: #c0c0c0;
$success: #118c7e;
$text: black;

$font-family: -apple-system, "Poppins", Helvetica, sans-serif;
$font-size: 16px;
$gap: 16px;
$navbar-y: 60px;
$padding-x: 75px;
$padding-y: 75px;
$radius: 4px;
$corner: 16px;
$screen: 1920px;
$spacing: 32px;

$xl: 1280px;
$lg: 1024px;
$md: 768px;
$sm: 640px;
$xs: 425px;

$dark-0: #111;
$dark-1: lighten($dark-0, 7%);
$dark-2: lighten($dark-0, 15%);

$blue-0: #4f90d3;
$blue-1: #3e73ac;
$blue-2: #0c437d;
$blue-3: #1d4976;
$blue-4: #132f4c; //bg-copy & box-hover
$blue-5: #173a5e; //bg-copy-hover
$blue-6: #004c99; //bg-box-active
$blue-7: #071a2f; //nav-bg
$blue-8: #01356b; //box-hover
$blue-9: #011e3c; //bg & box
$blue-x: #0b1929; //bg-dark

$gray-0: #f4f6f9;
$gray-1: #e6e8ec;
$gray-2: #cdd2d6;
$gray-3: #8797a6;
$gray-4: #3f5060;
$gray-5: #b1bac2; //bg-gray
$gray-6: #848c94; //bg-dark-gray
$gray-7: #515151; //black-border
$gray-8: #121212; //black-box
$gray-9: #fafafa;

:root {
  --bup-primary-main: #3399ff;
  --bup-primary-light: #66b2ff;
  --bup-primary-dark: #0059b2;
  --bup-primary-contrastText: #000000de;
  --bup-secondary-main: #1f262e;
  --bup-secondary-contrastText: #2f3a46;
  --bup-secondary-light: #4b5157;
  --bup-secondary-dark: #151a20;
  --bup-primaryDark-main: #7b91a7;
  --bup-error-main: #eb0014;
  --bup-error-light: #ff99a2;
  --bup-error-dark: #c70011;
  --bup-success-main: #1db45a;
  --bup-success-light: #6ae79c;
  --bup-success-dark: #1aa251;
  --bup-warning-main: #dea500;
  --bup-warning-light: #ffdc48;
  --bup-warning-dark: #ab6800;
  --bup-info-main: #29b6f6;
  --bup-info-light: #4fc3f7;
  --bup-info-dark: #0288d1;

  --bup-common-background: #fafafa;
  --bup-common-backdrop: #000000a8;
  --bup-common-border: #ebedeb;
  --bup-common-empty: #ebebeb;
  --bup-common-item: #f5f5f5;
  --bup-common-hover: #f4f4f4ab;
  --bup-common-inset: #dae0e7;
  --bup-divider: #e1e1e1d9;
  --bup-skeleton: #d7d7d7;

  --bup-text-primary: #212121;
  --bup-text-secondary: #4f473b;
  --bup-text-tertiary: #575556;
  --bup-text-disabled: #00000080;
  --bup-text-icon: #00000080;
  --bup-text-important: #638eb4;

  --bup-paper-background: #fff;
  --bup-paper-border: #d9e2ed;
  --bup-paper-color: #111;

  --bup-navbar-background: #fff;
  --bup-navbar-color: #111;
  --bup-navbar-item-background: #fff;
  --bup-navbar-item-background-hover: #eeeef4;
  --bup-navbar-item-background-active: #0047b8;
  --bup-navbar-item-border: #fff;
  --bup-navbar-item-border-hover: #eaeaea;

  --bup-icon-background: #f3f6f9;
  --bup-icon-background-hover: #edeff0;
  --bup-icon-border: #e5eaf2;
  --bup-icon-border-hover: #d3d7de;
  --bup-icon-color: #6b7a90;
  --bup-icon-color-hover: #637186;

  --bup-input-background: #f3f6f9;
  --bup-input-background-hover: #edeff0;
  --bup-input-border: #e5eaf2;
  --bup-input-border-hover: #d3d7de;
  --bup-input-color: #6b7a90;
  --bup-input-color-hover: #637186;

  --bup-chip-background: #00000014;
  --bup-chip-background-hover: #edeff0;
  --bup-chip-background-active: #000;
  --bup-chip-border: #e5eaf2;
  --bup-chip-border-hover: #d3d7de;
  --bup-chip-border-active: #000;
  --bup-chip-color: #000000de;
  --bup-chip-color-hover: #000000ef;
  --bup-chip-color-active: #fff;

  --bup-button-primary: #0047b8;
  --bup-button-primary-hover: #0041a9;
  --bup-button-secondary: #1568a7;
  --bup-button-tertiary: #00a7e5;
  --bup-button-background: #fff;
  --bup-button-border: #dbdbdb;
  --bup-button-border-hover: #c0c0c0;
  --bup-button-color: #000000cc;

  --bup-link-color: #0b57d0;
  --bup-link-color-hover: #003a75;
  --bup-link-background: #0047b816;
  --bup-link-background-hover: #0047b810;
  --bup-link-border: #0047b854;
  --bup-link-border-hover: #0047b888;

  --bup-tab-background: #0047b814;
  --bup-tab-border: #0047b865;
  --bup-tab-color: #0047b8;

  --bup-alert-background: #e2e6f0;
  --bup-alert-color: #0047b8;

  --bup-success-background: #118c7e1a;
  --bup-success-border: #118c7e50;
  --bup-success-color: #118c7e;
  --bup-success-whatsapp: #118c7e;

  --bup-danger-background: #d32f2f1a;
  --bup-danger-border: #d32f2f50;
  --bup-danger-color: #d32f2f;

  --bup-premium-alert-background: #c8981d33;
  --bup-premium-alert-border: #ebd8ae;
  --bup-premium-alert-color: #c8981d;
  --bup-premium-alert-button-background: #f6f1e5;
  --bup-premium-alert-button-background-hover: #f1e9d6;
  --bup-premium-alert-button-border: #f6c77c;
  --bup-premium-alert-button-border-hover: #f6cb86;
  --bup-premium-alert-button-color: #c8981d;

  --bup-premium-button-background: #f6c77c;
  --bup-premium-button-background-hover: #f7cd8b;
  --bup-premium-button-color-active: #f7c77d;
  --bup-premium-button-color: #936f15;

  --bup-disabled-button-background: #cdd3d7;
  --bup-disabled-button-color: #7f8c8d;
  --bup-disabled-button-border: #adb3b7;

  --bup-avatar-background: #bdbdbd;
  --bup-avatar-color: #767676;

  --bup-border-radius: 12px;
  --bup-font-size: 16px;
  --bup-font-weight: 400;
  --bup-rounded: 4px;
}

@import "builder";
@import "frames";
@import "fonts";
@import "functions";
@import "mixin";
@import "screen";
@import "common";
