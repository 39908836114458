div.BupAlert-root {
	@include paper-box;

	&.MuiAlert-standardInfo {
		background: rgba($primary, 0.08);

		svg.MuiSvgIcon-root {
			fill: darken($primary, 4%);
		}
		div.MuiAlert-message {
			font-size: 14px;
		}
		&.Bup-premium {
			background: rgba($golden, 0.16);

			svg.MuiSvgIcon-root {
				fill: darken($golden, 4%);
			}
			div.MuiAlert-message {
				strong,
				b {
					color: darken($golden, 4%);
				}
			}
			div.MuiAlert-action {
				button.MuiIconButton-root {
					border-radius: 50%;
				}
			}
		}
	}
	@media only screen and (max-width: $xs) {
		margin: 0;
	}
}
