button.BupLink-root {
  border: none;
  background: transparent;
  cursor: pointer;
  font-family: inherit;
}
a.BupLink-root {
  &.Bup-url:not(.Bup-disabled) {
    color: $primary;

    span {
      text-decoration: underline;
    }
  }
  &.Bup-disabled {
    pointer-events: none;
    user-select: none;
    color: #888;
  }
}
