.Bup-active {
  cursor: default !important;
  pointer-events: none;
}
.Bup-clickeable {
  cursor: pointer;
}
.Bup-disabled {
  cursor: default;
  pointer-events: none;
  user-select: none;
}
.Bup-hidden {
  display: none !important;
}
.Bup-grayscale {
  filter: grayscale(1);
}
.Bup-capitalize {
  text-transform: capitalize;
}
.Bup-lowercase {
  text-transform: lowercase;
}
.Bup-uppercase {
  text-transform: uppercase;
}
.Bup-readOnly {
  user-select: none;
}
.Bup-through {
  text-decoration: line-through;
}
.Bup-overline {
  text-decoration: overline;
}
.Bup-underline {
  text-decoration: underline;
}
.Bup-selected {
  cursor: pointer;
}
.Bup-wrapper {
  &:empty {
    display: none !important;
  }
}
.Bup-hover {
  opacity: 0.9;
  &:hover {
    filter: grayscale(0.33);
    opacity: 1;
  }
}
.Bup-rounded {
  border-radius: 16px;
}
.Bup-outlined {
  border: 1px solid $border;
}
.Bup-opacity-75 {
  opacity: 0.75;
}
.Bup-opacity-50 {
  opacity: 0.5;
}
.Bup-opacity-25 {
  opacity: 0.25;
}
i {
  &.Bup-diamond {
    @include text($diamond);
  }
  &.Bup-gold {
    @include text($golden);
  }
  &.Bup-silver {
    @include text($silver);
  }
  &.Bup-bronze {
    @include text($bronze);
  }
}
hr.MuiDivider-fullWidth {
  margin: 16px 0 !important;
}
.Bup-sync {
  -webkit-animation: spin 3s linear infinite;
  -moz-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}
