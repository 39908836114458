iframe.BupVideo-root {
  @include video;
  border-radius: 16px;
  border: none;

  &:not(.BupVideo-reel) {
    @include video;
    width: $lg;
  }
  &.BupVideo-reel {
    height: 400px;
    width: 222px;
  }
}
