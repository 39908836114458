section.BupEvent-root {
  header.BupEvent-header {
    background: $background;
    border-radius: calc(#{$corner} + 1px);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 1px solid $border;
    margin-top: 0 !important;
    padding-bottom: 16px;
    width: 100%;

    .BupEvent-media,
    img.BupSkeleton-image {
      height: 320px;
      display: flex;
      justify-content: flex-end;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      border: none;
      align-items: flex-start;

      @media only screen and (max-width: $md) {
        height: 288px;
      }
      @media only screen and (max-width: $sm) {
        height: 256px;
      }
      @media only screen and (max-width: $xs) {
        border-radius: 0;
        border: none !important;
        height: 224px;
      }
      button.BupButton-icon {
        background: rgba(0, 0, 0, 0.4);
        color: white;

        &:hover {
          background: rgba(0, 0, 0, 0.8);
        }
      }
    }
    .BupEvent-photo {
      @include square;

      border: 4px solid $bg-0;
      border-radius: $corner;
      margin-bottom: 16px;
      margin-top: -140px;
      width: 160px;

      button.BupButton-icon {
        background: $bg-2;
        border-radius: 50%;
        color: black;
        height: 30px;

        i.BupIcon-root {
          font-size: 20px !important;
        }
      }
      & + span.BupEvent-file {
        position: relative;
        width: fit-content;

        button.BupButton-icon {
          background: $bg-0;
          border-radius: 18px;
          height: 32px;
          margin-left: 120px;
          margin-top: -56px;
          position: absolute;
          width: 32px;

          i.BupIcon-root {
            font-size: 21px !important;
          }
        }
      }
      @media only screen and (max-width: $xs) {
        margin: -140px auto 16px;

        & + span.BupEvent-file {
          margin-left: calc(50% - 80px);
        }
      }
    }
    div.BupEvent-actions {
      position: relative;

      button.MuiButton-colorInherit,
      button.BupButton-icon {
        background: $bg-3;

        &:hover {
          background: $bg-5;
        }
      }
      button.BupButton-icon {
        display: none;
      }
      button.MuiSkeleton-root {
        height: 36px;
        border-radius: 18px;
        border: none;

        &.BupSkeleton-button {
          width: 120px;
        }
        &.BupSkeleton-icon {
          width: 36px;
        }
      }
      @media only screen and (max-width: $sm) {
        margin-top: 16px;
      }
    }
    div.BupEvent-content {
      margin: 0;
      padding: 16px;

      h1.MuiTypography-h1 {
        @include clamp(1);
        font-size: 32px;
        font-weight: 500;
        width: 100%;

        span.MuiSkeleton-root {
          min-width: 300px;
        }
        @media only screen and (max-width: $sm) {
          @include clamp(2);
        }
      }
      p.MuiTypography-body1 {
        font-size: 15px;
      }
      button.BupButton-icon {
        aspect-ratio: 1 !important;
        width: 36px;
      }
      span.MuiTypography-body2 {
        @include row-center;
        justify-content: flex-start;
        font-size: 13.5px;
        font-weight: 400;
        gap: 8px;
        text-transform: uppercase;

        @media only screen and (max-width: $xs) {
          justify-content: center;
        }
      }
      div.MuiChip-root {
        height: 18px;

        span.MuiChip-label {
          font-size: 12px;
        }
      }
      div.BupUser-root {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-left: 4px;

        div.BupUser-name {
          align-items: center;
          display: flex;
          justify-content: flex-start;
          gap: 12px;

          h1.MuiTypography-h1 {
            width: fit-content;
          }
          i.BupIcon-verified {
            color: $primary;
            font-size: 20px;
          }
          div.BupUser-status {
            align-items: center;
            display: flex;
            gap: 16px;
            height: fit-content;

            svg.BupLogo-root {
              height: 18px;
              margin-top: 2px;
              width: 18px;
            }
            &:empty {
              display: none;
            }
          }
          @media only screen and (max-width: $xs) {
            justify-content: center;
          }
        }
        div.BupUser-plan {
          align-items: center;
          display: flex;
          gap: 4px;

          i.BupIcon-root {
            font-size: 20px;
          }
        }
        @media only screen and (max-width: $sm) {
          align-items: flex-start;
          gap: 16px;
          flex-direction: column;
        }
        @media only screen and (max-width: $xs) {
          align-items: center;
          padding-left: 0;
        }
      }
      @media only screen and (max-width: $xs) {
        padding: 16px 12px 8px;
        text-align: center;
      }
      &:last-child {
        margin-bottom: 8px;
      }
      &:has(div.BupEvent-overview) {
        p.MuiTypography-body1,
        span.MuiTypography-body2 {
          display: none;
        }
      }
    }
    div.BupEvent-overview {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 16px;

      div {
        i.BupIcon-root {
          @include row-center;
          height: 40px;
          width: 40px;
          border: 1px solid $border;
          border-radius: 8px;
          font-size: 22px;
          color: #666;
        }
        &.BupEvent-startDate {
          border: 1px solid $border;
          border-radius: 8px;
          height: 40px;
          width: 40px;
          user-select: none;

          span {
            display: block;
            text-align: center;

            &:first-child {
              background-color: $border;
              border-top-left-radius: 8px;
              border-top-right-radius: 8px;
              color: black;
              font-size: 9px;
              font-weight: 600;
              height: 16px;
              padding: 2px;
              text-transform: uppercase;
            }
            &:last-child {
              color: black;
              font-size: 15px;
              font-weight: 400;
              height: 24px;
              padding: 3px;
              border-radius: 8px;
            }
          }
        }
        &.BupEvent-info {
          span {
            @include clamp(1);
            max-width: 100%;

            &:first-child {
              font-size: 16px;
              font-weight: 500;
              min-width: 250px;
            }
            &:last-child {
              font-size: 13.5px;
              color: #666;
              min-width: 200px;
            }
          }
        }
      }
      @media only screen and (max-width: $sm) {
        grid-template-columns: 1fr;
      }
      @media only screen and (max-width: $xs) {
        display: none;
      }
    }
    div.BupEvent-thumbs {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0 16px;

      @media only screen and (max-width: $sm) {
        flex-direction: column;
        gap: 8px;
        margin-bottom: 8px;
      }
      @media only screen and (max-width: $xs) {
        align-items: center;
        justify-content: center;
        padding: 0 12px;
        margin-bottom: 0;
      }
    }
    @media only screen and (max-width: $xs) {
      border-radius: 0 !important;
      border: none;
    }
  }
  main.BupEvent-content {
    padding: 16px;

    div.BupPaper-root {
      box-shadow: none;
    }
    div.BupEvent-description {
      p {
        font-size: 14px;
        line-height: 1.33;
        margin: 12px 0 0;
        white-space: pre-wrap;
        color: #666;

        &.Bup-clamp {
          @include clamp(5);
        }
        a {
          color: $secondary;

          &:hover {
            text-decoration: underline;
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
      a.BupLink-root {
        color: $primary;
        display: none;
        font-size: 13.5px;
        font-weight: bold;
      }
    }
    div.BupEvent-details {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 8px;
      margin-top: 8px;

      div.MuiPaper-root {
        @include paper-box;
        background: $background;
        border-radius: $corner;
        align-items: center;
        display: flex;
        gap: 16px;
        padding: 16px;

        h5.MuiTypography-h5 {
          font-size: 16px;
          font-weight: 500;
        }
        span.MuiTypography-p {
          font-size: 13.5px;
          color: #666;
        }
        a.BupLink-href {
          @include row-center;
          cursor: pointer;
          gap: 4px;

          i.BupIcon-root {
            font-size: inherit;
            margin-top: 1px;
          }
          &:hover {
            color: $tertiary;
          }
        }
        s {
          margin-left: 4px;
          text-decoration: line-through;
          font-size: 12px;
        }
        @media only screen and (max-width: $xs) {
          border-radius: 0 !important;
          padding: 12px;
        }
      }
      @media only screen and (max-width: $sm) {
        grid-template-columns: 1fr;
      }
      @media only screen and (max-width: $xs) {
        border-top: 1px solid $border;
        border-bottom: 0.5px solid $border;
        grid-gap: 0;
        margin-top: 16px;

        div.MuiPaper-root {
          border-top: none;
          border-width: 0.5px;
        }
      }
    }
    & > hr.MuiDivider-root {
      margin: 16px 0 !important;
    }
  }
}
section.BupEvents-grid {
  button.MuiButton-outlinedInherit {
    margin-left: auto;
    margin-right: auto;
    padding: 4px 24px;
  }
  .blurred {
    filter: blur(10px);
    pointer-events: none;
    opacity: 0.6;

    &:hover {
      filter: blur(10px);
    }
  }
}
section.BupEvents-grid {
  position: relative;
  .blur-overlay {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    @media only screen and (max-width: 640px) {
      top: 20%;
    }
    @media only screen and (max-width: 460px) {
      top: 22%;
    }
  }

  .BupButton-wide {
    z-index: 10;
    pointer-events: auto;
  }
}

.Bup-development {
  section.BupEvent-root {
    & > *,
    main.BupEvent-content * {
      background-color: $dark-0 !important;
      color: white !important;
    }
    * {
      border-color: $dark-2 !important;
      color: white !important;
    }
    button.MuiButton-containedInherit {
      color: black !important;

      * {
        color: inherit !important;
      }
    }
    svg {
      border: none;
    }
  }
}
