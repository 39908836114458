i.BupIcon-root {
	font-size: inherit;
	margin: 0;
	padding: 0;
	transition: all 0.2s ease;

	&.Bup-circle {
		@include row-center;
		@include font(22px);
		aspect-ratio: 1;
		border-radius: 50%;
		cursor: pointer;
		height: 44px;
		width: 44px;

		&:hover {
			background: rgba($bg-4, 40%);
		}
		&:active {
			background: rgba($bg-4, 80%);
		}
		&.Bup-active {
			color: $primary;
		}
	}
	&.Bup-square {
		@include row-center;
		@include font(22px);
		aspect-ratio: 1;
		border-radius: $radius;
		cursor: pointer;
		height: 44px;
		width: 44px;

		&:hover {
			background: rgba($bg-4, 40%);
		}
		&:active {
			background: rgba($bg-4, 80%);
		}
		&.Bup-active {
			color: $primary;
		}
	}
}
