.BupFlex-around {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: $gap;
}
.BupFlex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: $gap;
}
.BupFlex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: $gap;
}
.BupFlex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: $gap;
}
.BupFlex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: $gap;
}
