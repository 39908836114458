div.BupCard-root {
	&.BupCard-preview {
		background: transparent;
		border-radius: $corner;
		box-shadow: none;
		color: inherit;
		cursor: pointer;
		width: 100%;

		div.MuiCardMedia-root {
			@include paper-box;
			@include video;

			align-items: flex-end;
			background-color: $bg-4;
			border-radius: $corner;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			margin-bottom: 8px;
			padding: 4px;
			transform: scale 0.3s ease;

			&:hover {
				transform: scale(1.008);
			}

			button.BupButton-icon {
				color: $bg-0;
				text-shadow: 0px 0px 8px black;
				visibility: hidden;

				&.marked {
					color: lighten($danger, 4%);
				}
				i.BupIcon-root {
					font-size: 22px !important;
				}
			}
			span.MuiTypography-span {
				@include blur(6px);
				background: rgba($bg-0, 0.9);
				border: 1px solid rgba($border, 0.9);
				border-radius: 32px;
				border-right: none;
				border-bottom-right-radius: 0;
				border-top-right-radius: 0;
				color: black;
				font-size: 12px;
				font-weight: bold;
				margin: 8px;
				margin-right: -4px;
				padding: 2px 8px;
				user-select: none;
			}
			@media only screen and (max-width: $xs) {
				border-bottom-right-radius: 0;
				border-bottom-left-radius: 0;
			}
		}
		span.BupCard-skeletonMedia {
			@include video;

			border-radius: $corner;
			display: block;
			margin-bottom: 8px;
		}
		div.MuiCardContent-root {
			color: inherit;
			padding-bottom: 4px;
			width: 100%;

			div.MuiTypography-h6 {
				@include clamp(1);
				color: inherit;
				font-size: 20px;
				margin: 0;

				&.BupCard-member {
					align-items: center;
					display: flex;
					font-size: 18px;
					justify-content: space-between;

					i.BupIcon-root {
						color: $primary;
						font-size: 18px;
					}
				}
				b {
					font-weight: 500;
				}

				@media only screen and (max-width: $xs) {
					@include clamp(2);
					line-height: 1.25;
					margin: 4px 0;
				}
			}
			p.MuiTypography-body2 {
				@include clamp(1);
				align-items: center;
				color: inherit;
				font-size: 13.5px;
				display: flex;
				justify-content: space-between;
				max-width: 100%;
				white-space: nowrap;
				gap: 8px;

				b {
					color: inherit;
				}
				span.text {
					@include clamp(1);
				}
			}
			p.MuiTypography-body1 {
				@include clamp(2);
				color: inherit;
				font-size: 13.5px;
				margin: 8px 0 12px;
				max-width: 100%;

				@media only screen and (max-width: $xs) {
					display: none;
				}
			}
			span.MuiTypography-span {
				@include clamp(1);
				color: inherit;
				display: flex;
				justify-content: space-between;
				font-size: 12.5px;
				white-space: nowrap;
				width: 100%;

				small {
					font-size: 10px;
				}
				i.BupIcon-root {
					font-size: 16px;
				}
			}
			div.MuiBox-root {
				align-items: center;
				display: flex;
				gap: 4px;
			}
			&:has(span.BupCard-rating) {
				display: flex;
				flex-direction: column-reverse;

				span.BupCard-rating svg {
					height: 18px;
					width: 18px;
				}
			}
			@media only screen and (max-width: $xs) {
				border: 1.5px solid $border;
				border-top: none;
				border-bottom-right-radius: $corner;
				border-bottom-left-radius: $corner;
				padding: 8px 8px 8px;
				margin-top: -8px;
				background-color: white;
			}
		}
		&.BupCard-minified {
			div.MuiCardMedia-root {
				button.BupButton-icon {
					display: none;
				}
			}
			div.MuiCardContent-root {
				span.MuiTypography-span {
					font-size: 13.5px;

					small,
					s {
						display: none;
					}
				}
				div.MuiTypography-h6 {
					font-size: 20px;
				}
				p.MuiTypography-body2 {
					font-size: 13px;
				}
			}
		}
	}
}
