main.BupLoading-root {
	@include center;
	height: 100vh;
	gap: $gap;
	padding-bottom: $navbar-y;

	svg {
		height: 80px;
		opacity: 0.9;
		-webkit-animation: spin 5s linear infinite;
		-moz-animation: spin 5s linear infinite;
		animation: spin 5s linear infinite;
	}
}
