@import "styles/config";

section.BupLogin-view {
  margin: 120px 0 0 !important;
  padding: 16px;

  h1.MuiTypography-h1,
  h2.MuiTypography-h2 {
    font-size: 36px;
    line-height: 1.15;
    margin: 0 !important;
    text-align: left !important;
  }
  button.MuiButton-root {
    width: 256px;
  }
  div.BupImage-root,
  img.BupImage-root {
    @include video;
    @include row-center;

    &:has(button.BupButton-icon) {
      cursor: pointer;

      &:hover {
        button.BupButton-icon {
          background: rgba(black, 0.66);
        }
      }
    }
    button.BupButton-icon {
      @include movie;
      background: rgba(black, 0.5);
      color: white;
      border-radius: $corner;
      width: 80px;

      i.BupIcon-root {
        font-size: 32px !important;
      }
    }
  }
  span.MuiTypography-p {
    font-size: 15px !important;
    font-weight: 300 !important;
  }
  @media only screen and (max-width: $md) {
    margin: 96px 0 0;
    padding: 12px;

    div.BupLogin-create {
      height: calc(100vh - 60px - 88px);
      text-align: center;

      h3.MuiTypography-h3 {
        font-size: 24px;
      }
      span.MuiTypography-p {
        font-size: 18px;
      }
      svg.Bup-sync {
        height: 48px;
        width: 48px;
      }
    }
  }
}

@import "about";
@import "business";
@import "events";
@import "home";
@import "info";
@import "partner";
@import "pricing";
