footer.BupBrowser-footer {
	div.BupFooter-support {
		margin: $padding-y 0 calc($padding-y - 20px);
		margin-top: 0;
		gap: 48px;

		img {
			background-color: transparent;
			border: none;
			border-radius: 0;
			display: none;
			height: 40px !important;
			width: auto !important;

			&.BupLogo-large {
				height: 55px !important;
			}
			&.BupLogo-small {
				height: 20px !important;
			}
		}
	}
	div.BupFooter-social {
		gap: 24px;

		button.BupButton-icon {
			@include font(20px);
			color: $bg-8;
			cursor: pointer;
			height: 40px;
			width: 40px;

			&:hover {
				color: $primary;
			}
		}
	}
	nav.BupFooter-policies {
		border-top: 1px solid $border;

		span.BupLogo-span {
			@include font(18px, 500);
			@include logo($tertiary);
			margin: 0;
		}
		div {
			@include row-center;
			gap: 8px;
		}
		svg {
			aspect-ratio: 1;
			max-height: 100%;
			height: 28px;
		}
		ul {
			@include row-center;
			gap: 32px;
			font-size: 13px;
			flex-wrap: wrap;

			li {
				cursor: pointer;

				&:first-child {
					pointer-events: none;
				}
				&:hover {
					color: $tertiary;
				}
			}
		}
	}

	& > div,
	& > nav {
		@include row-center;
		@include font(14px);
		min-height: $navbar-y;
		gap: 32px;
	}
	@media only screen and (max-width: $lg) {
		nav.BupFooter-policies {
			padding: 16px 0;

			ul {
				display: flex;
				flex-direction: row;
				gap: 8px 24px;
				margin: 0;
			}
		}
	}
	@media only screen and (max-width: $md) {
		nav.BupFooter-policies {
			flex-direction: column-reverse;
			gap: 24px;
			padding: 24px 0;

			ul {
				gap: 8px 16px;
			}
		}
		div.BupFooter-support {
			flex-wrap: wrap;
			gap: 32px;

			img {
				height: 30px !important;

				&.BupLogo-large {
					height: 50px !important;
				}
				&.BupLogo-small {
					height: 15px !important;
				}
			}
		}
		div.BupFooter-social {
			gap: 16px;
		}
	}
	@media only screen and (max-width: $sm) {
		div.BupFooter-support {
			gap: 16px;

			img {
				height: 25px !important;

				&.BupLogo-large {
					height: 45px !important;
				}
				&.BupLogo-small {
					display: none;
				}
			}
		}
		div.BupFooter-social {
			gap: 8px;
		}
	}
}
.Bup-development {
	footer.BupBrowser-footer {
		nav.BupFooter-policies {
			border-color: $dark-2;

			span.BupLogo-span {
				@include logo(lighten($tertiary, 8%), 10%);
			}
		}
		div.BupFooter-social {
			button.BupButton-icon {
				color: $bg-7;

				&:hover {
					color: $tertiary;
				}
			}
		}
	}
}
