div.BupModal-root {
	@include row-center;
	background: rgba(black, 0.8);
	height: 100vh;
	left: 0;
	padding: 50px;
	position: fixed;
	top: 0;
	width: 100vw;
	z-index: 999;

	div.BupModal-paper {
		@include paper-box;
		background: white;
		border-radius: $corner;
		max-width: 100%;
		outline: none;
		padding: 0 !important;
		position: relative;
		width: $sm;
		z-index: 1100;

		&.BupModal-sizeMedium {
			width: $md;
		}
		&.BupModal-sizeLarge {
			width: calc($lg / 2 + $md/2);
		}
		&.BupModal-fullWidth {
			overflow-y: auto;
			width: $xl;
		}
	}
	div.BupModal-title {
		align-items: center;
		border-bottom: 1px solid $border;
		display: flex;
		justify-content: space-between;
		padding: 12px 8px 12px 16px;

		h2.MuiTypography-h6 {
			font-size: 24px;
		}
	}
	div.BupModal-actions {
		padding: 16px 16px 16px 12px;

		div.MuiBox-root {
			display: flex;
			gap: 8px;
		}
		button.BupButton-icon {
			border-radius: 50%;
			height: 40px;
			width: 40px;

			i.BupIcon-root {
				font-size: 22px !important;
			}
			&.Bup-selected {
				background: rgba($primary, 0.08);
				color: rgba($primary, 0.8);
			}
		}
		button.BupButton-icon {
			border-radius: 36px;
			height: 36px;

			&:disabled {
				background: $bg-3;
			}
		}
	}
	div.BupModal-content {
		padding: 16px 16px 0;

		& > div {
			display: flex;
			gap: 16px;

			div.BupAvatar-root {
				height: 36px;
				width: 36px;
			}
			textarea {
				border: none;
				font-size: 14px;
				padding: 10px 0;
				outline: none;
				resize: none;
				height: 180px;
				width: 100%;
			}
		}
		p.MuiTypography-p {
			font-size: 14.5px;
			margin-bottom: 8px;

			&:not(:last-child) {
				margin-bottom: 16px;
			}
		}
		span.Bup-disclaimer {
			background: rgba($primary, 0.08);
			border-radius: $radius;
			font-size: 12.5px;
			line-height: 1.25;
			padding: 12px 16px;
			margin: 4px 0;

			a {
				color: darken($primary, 8%);
				font-weight: bold;
			}
		}
		form.BupModal-form {
			div.BupForm-field {
				div.BupForm-inline {
					position: relative;

					input.BupForm-input {
						border-radius: 20px;
						border: 1.5px solid $border;
						height: 40px;
						width: 100%;
						outline: none;
						padding: 0 48px;

						&::placeholder {
							color: $bg-7;
						}
						&:focus {
							border-color: rgba(black, 0.33);
						}
					}
					i.BupIcon-root {
						color: black;
						font-size: 20px !important;
					}
					span.BupForm-prefix {
						position: absolute;
						left: 16px;
						top: 10px;
					}
					span.BupForm-sufix {
						position: absolute;
						right: 16px;
						top: 10px;

						i.BupIcon-root {
							color: $success;
						}
					}
				}
			}
		}
	}
	&:has(iframe) {
		div.BupModal-paper {
			background: transparent;
			width: fit-content;

			iframe.Bup-video {
				border: none;
				border-radius: $corner;
				display: block;

				&:not(.Bup-reel) {
					@include video;
					max-width: 100%;
					width: 100%;
				}
				&.Bup-reel {
					@include reel;

					height: 80vh;

					@media only screen and (max-width: $xs) {
						height: auto;
						width: calc(100vw - 16px);
					}
				}
			}
		}
	}
	@media only screen and (max-width: $sm) {
		padding: 4px;
	}
}
