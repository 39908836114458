@import "styles/config";

main.BupPartner-root,
main.BupBusiness-root {
  position: relative;
  overflow: hidden;

  div.BupPartner-avatars {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    margin-bottom: -60px;

    img {
      border-radius: 50%;
      border: 0.5px solid $border;
      filter: contrast(1.3) brightness(0.95);
    }
    div {
      @include row-center;
    }
    @media only screen and (max-width: $lg) {
      width: $xl;
      position: absolute;
      left: calc(50vw - #{$xl}/ 2);
    }
    @media only screen and (max-width: $sm) {
      left: calc(50vw - #{$lg}/ 2 + 32px);
      width: $lg;
    }
    @media only screen and (max-width: $xs) {
      left: calc((50vw - #{$lg}/ 2) * 0.5);
      zoom: 0.5;
    }
  }
  div.BupPartner-image {
    display: grid;
    grid-template-columns: $sm 1fr;
    gap: 20px;

    img.BupImage-root {
      @include video;
      width: 100%;
    }
    @media only screen and (max-width: $xl) {
      grid-template-columns: $xs 1fr;
    }
    @media only screen and (max-width: $lg) {
      grid-template-columns: 1fr;
    }
  }
  div.BupPartner-logo {
    @include row-center;
    background-color: #001c4c;
    height: 120px;
    margin-bottom: -16px;
    margin-top: 0;

    img {
      background-color: transparent;
      object-fit: contain;
      height: 90px;
    }
    @media only screen and (max-width: $md) {
      height: 100px;
      margin-bottom: 0;

      img {
        height: 75px;
      }
    }
    @media only screen and (max-width: $sm) {
      height: 80px;

      img {
        height: 60px;
      }
    }
  }
  div.BupPartner-video {
    border-bottom: 1px solid $border;
    padding-bottom: 100px;

    @media only screen and (max-width: $lg) {
      padding: 0 16px;
      border: none;
    }
    @media only screen and (max-width: $sm) {
      iframe.BupVideo-root {
        aspect-ratio: 400/225;
        border-radius: 8px;
        height: calc(320px * 400 / 225);
        width: 320px;
      }
    }
    @media only screen and (max-width: $xs) {
      padding: 0 12px;

      iframe.BupVideo-root {
        height: calc((100vw - 24px) * 400 / 225);
        width: 100%;
      }
    }
  }
  ul.BupPartner-features {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;

    & > li {
      @include square;
      @include row-center;
      background-color: $bg-2;
      border: 1px solid $bg-3;
      border-radius: 16px;
      color: black;
      font-size: 16px;
      font-weight: 500;
      padding: 20px;
      text-align: center;

      &:has(img) {
        background-color: $bg-5;
        padding: 0;

        img {
          height: 100%;
          width: 100%;
        }
      }
    }
    &:not(.BupAdvertising-features) {
      @media only screen and (max-width: $lg) {
        grid-template-columns: repeat(4, 1fr);
        gap: 5px;
      }
      @media only screen and (max-width: $sm) {
        grid-template-columns: repeat(2, 1fr);

        & > li {
          $newOrder: (
            1: 2,
            2: 1,
            3: 4,
            4: 3,
            9: 10,
            10: 9,
          );

          @for $i from 1 through 12 {
            &:nth-child(#{$i}) {
              order: map-get($newOrder, $i) or $i;
            }
          }
        }
      }
    }
    &.BupAdvertising-features {
      grid-template-columns: repeat(5, 1fr);

      @media only screen and (max-width: $lg) {
        grid-template-columns: repeat(2, 1fr);
        gap: 5px;
      }
    }
  }
  ul.BupPartner-steps {
    gap: 12px;

    & > li {
      align-items: center;
      background-color: rgba(#001c4c, 0.95);
      background-color: $bg-2;
      border-radius: 16px;
      color: black;
      display: grid;
      font-size: 14px;
      gap: 12px;
      grid-template-columns: 60px auto;
      min-height: 100px;
      padding: 12px;
      padding-right: 24px;

      h4.MuiTypography-h4 {
        display: inline-block;
        font-size: 60px;
        font-weight: bold;
        opacity: 0.33;
        text-align: center;
        width: 60px;
      }
      span.MuiTypography-p {
        font-size: 14px;
      }
      @media only screen and (max-width: $xs) {
        grid-template-columns: 48px auto;
        padding-left: 6px;

        h4.MuiTypography-h4 {
          font-size: 48px;
        }
        span.MuiTypography-p {
          font-size: 13.5px;
        }
      }
    }
    @media only screen and (max-width: $xs) {
      gap: 6px;
    }
  }
  section {
    background-color: white !important;

    @media only screen and (max-width: $xs) {
      &.BupStack-root {
        padding-top: 0;
      }
      &.BupDivider-bottom {
        padding-bottom: 60px !important;
      }
    }
    &.BupPartner-contact {
      align-items: center;
      @media only screen and (max-width: $xs) {
        text-align: center;

        p.MuiTypography-body1 {
          font-size: 15px;
        }
      }
    }
    div.BupStack-root:has(.BupPartner-avatars) {
      @media only screen and (max-width: $lg) {
        margin-bottom: 500px;
      }
      @media only screen and (max-width: $xs) {
        margin-bottom: 275px;
      }
    }
  }
  & {
    h2.MuiTypography-h2 {
      font-size: 36px;

      @media only screen and (max-width: $xs) {
        font-size: 28px !important;
        line-height: 1.25 !important;
      }
    }
    h3.MuiTypography-h3 {
      font-size: 30px;
      margin-bottom: 32px;

      @media only screen and (max-width: $md) {
        font-size: 24px;
      }
      @media only screen and (max-width: $xs) {
        text-align: center;
        font-size: 22px;
        font-weight: 600;
      }
    }
    h5.MuiTypography-h5 {
      font-size: 15px;
      width: 80%;

      @media only screen and (max-width: $xl) {
        width: 100%;
      }
      @media only screen and (max-width: $xs) {
        text-align: center;
      }
    }
    span.Bup-disclaimer {
      font-size: 13.5px;

      @media only screen and (max-width: $xs) {
        text-align: center;
      }
    }
    span.Bup-subtitle {
      font-size: 16px;
      font-weight: 300;
      margin-top: -28px;
      margin-bottom: 16px;

      @media only screen and (max-width: $xs) {
        text-align: center;
      }
    }
    p.Bup-info {
      margin: 64px auto 0;
      text-align: center;
      max-width: 100%;
      width: $sm;
    }
    button.BupButton-wide {
      @media only screen and (max-width: $xs) {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}
