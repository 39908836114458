button.BupButton-root {
	border-radius: 18px;
	cursor: pointer;
	height: 36px;
	font-weight: 500;
	text-transform: none;
	white-space: nowrap;
	width: fit-content;

	&.MuiButton-contained {
		&.Bup-success {
			background: $success;
		}
		&.Bup-danger {
			background: $danger;
		}
		&:has(.fa-whatsapp) {
			@include background($success);
			border: none !important;
			color: white;

			&:hover {
				@include background(darken($success, 4%));
				color: white;
			}
			&:disabled {
				filter: grayscale(1);
				background: $bg-2 !important;
				color: darken($bg-6, 20%) !important;
				pointer-events: none;
			}
		}
	}
	&.MuiButton-outlined,
	&.MuiButton-text {
		&.Bup-success {
			border-color: $success;
			color: $success;
		}
		&.Bup-danger {
			border-color: $danger;
			color: $danger;
		}
		&.MuiButton-outlinedInherit {
			border-color: rgba(black, 0.4);
		}
	}
	&.MuiButton-sizeSmall,
	&.MuiIconButton-sizeSmall {
		border-radius: 16px;
		font-size: 12px;
		height: 32px;

		i.BupIcon-root {
			font-size: 14px;
		}
	}
	&.MuiIconButton-sizeSmall {
		i.BupIcon-root {
			font-size: 16px;
		}
	}
	&.MuiButton-sizeMedium,
	&.MuiIconButton-sizeMedium {
		border-radius: 18px;
		font-size: 13px;
		height: 36px;

		i.BupIcon-root {
			font-size: 15px;
		}
	}
	&.MuiIconButton-sizeMedium {
		i.BupIcon-root {
			font-size: 18px;
		}
	}
	&.MuiButton-sizeLarge,
	&.MuiIconButton-sizeLarge {
		border-radius: 20px;
		font-size: 14px;
		height: 40px;

		i.BupIcon-root {
			font-size: 16px;
		}
	}
	&.MuiIconButton-sizeLarge {
		i.BupIcon-root {
			font-size: 20px;
		}
	}
	&.MuiButton-fullWidth {
		width: 100%;
	}
	&.BupButton-icon {
		@include circle;
		border-radius: 50%;
		width: auto;
	}
	&.BupButton-square {
		border-radius: 4px !important;
	}
	&.Bup-active {
		background: rgba($primary, 0.12) !important;
		border-color: rgba($primary, 0.36) !important;
		color: $primary !important;
		cursor: wait;
	}
	&.Bup-loading {
		pointer-events: none;
		filter: grayscale(0.33);
		opacity: 0.9;
	}
	&.Bup-disabled {
		opacity: 0.66;
	}
	&.BupButton-dark {
		@include background($color-8);
		color: $bg-0;

		&:hover {
			@include background($color-9);
			color: $bg-0;
		}
	}
	&.BupButton-light {
		@include background($color-5);
		color: $bg-0;

		&:hover {
			@include background($color-6);
			color: $bg-0;
		}
	}
	&.BupButton-inherit {
		@include background($background);
		background: $background;
		color: $dark;

		&:hover {
			background: darken($background, 5%);
			color: $dark;
		}
	}
	&.BupButton-wide {
		font-size: 14px;
		line-height: 14px;
		width: 225px;

		&:not(.BupButton-dark):not(.BupButton-light) {
			background-color: transparent;
			border: 1.5px solid $bg-7;

			&:hover {
				background-color: $bg-9;
				border-color: $bg-9;
				color: $bg-0;
			}
		}
		&.BupButton-black {
			background-color: $bg-9 !important;

			&:hover {
				background-color: #111 !important;
			}
		}
	}
	.BupLogo-root {
		height: 16px;
		margin-left: 8px;
	}
}
.Bup-development {
	button.BupButton-root {
		&.BupButton-dark {
			@include background(lighten($color-8, 8%));
			color: $bg-0;

			&:hover {
				@include background(lighten($color-9, 8%));
				color: $bg-0;
			}
		}
		&.BupButton-wide {
			&:not(.BupButton-dark):not(.BupButton-light) {
				&:hover {
					background-color: $bg-3;
					border-color: $bg-3;
					color: $bg-9;
				}
			}
		}
		&.Bup-active {
			background: rgba(lighten($tertiary, 4%), 0.6) !important;
			border-color: rgba(lighten($tertiary, 80%), 0.4) !important;
			color: rgba(lighten($tertiary, 80%), 0.8) !important;
			cursor: wait;
		}
		&.MuiButton-containedPrimary {
			background: rgba($tertiary, 0.8);
		}
		&.MuiButton-outlinedPrimary {
			border-color: rgba($tertiary, 0.8);
			color: $tertiary;
		}
	}
}
