@import "styles/config";

main.BupHome-root {
  header.BupHome-header {
    @include col-center;
    height: min(calc(100vh - 175px), $screen / 2);
    gap: 20px;
    padding: 0 16px;
    text-align: center;

    h1.MuiTypography-h1 {
      @include font(50px, 400);
      color: #444;
      margin-top: $navbar-y;
      max-width: 900px;

      b {
        color: darken($tertiary, 4%);
        font-weight: 500;
      }
    }
    span.MuiTypography-p {
      @include font(20px, 300);
      color: #444;
      max-width: $sm;
    }
    button.BupButton-root {
      font-size: 14px;
      margin-top: 8px;
    }
    div.Bup-scroll {
      @include row-center;
      bottom: 170px;
      cursor: default;
      height: 36px;
      left: calc(50vw - 18px);
      position: absolute;
      width: 36px;
      z-index: 2;

      i {
        @include font(36px);
        color: $bg-7;
      }
    }
    @media only screen and (max-width: $lg) {
      height: calc(100vh - 175px);

      h1.MuiTypography-h1 {
        @include font(45px, 400);
        max-width: $md;
      }
      span.MuiTypography-p {
        @include font(16px, 300);
      }
      div.Bup-scroll {
        bottom: 170px;
      }
    }
    @media only screen and (max-width: $sm) {
      padding: 0 12px;

      h1.MuiTypography-h1 {
        @include font(36px, 400);
      }
    }
    @media only screen and (max-width: $xs) {
      h1.MuiTypography-h1 {
        span {
          display: block;
        }
      }
      div.Bup-scroll {
        bottom: 140px;
      }
    }
  }
}

@import "gallery";
@import "explore";
@import "discover";
@import "picture";
@import "pricing";
@import "banco";

.Bup-development {
  main.BupHome-root {
    header.BupHome-header {
      h1.MuiTypography-h1 {
        color: #ccc;

        b {
          color: lighten($tertiary, 8%);
        }
      }
    }
  }
}
