@import "browser";
@import "modal";
@import "topbar";
@import "footer";

section.BupError-root {
  @include row-center;
  height: 100vh;
  width: 100vw;

  svg.BupLogo-root {
    height: 60px;
    width: 60px;
  }
  span {
    &.BupLogo-span {
      @include font(40px, 500);
      @include logo($tertiary);
    }
    &.BupLogo-slogan {
      @include font(13.5px, 400);
      color: $text;
      padding-left: 2px;
      white-space: nowrap;
    }
  }
}
div.BupDrawer-root div.MuiPaper-root {
  border-top-left-radius: $corner;
  border-top-right-radius: $corner;
}
ul.BupDrawer-content {
  padding: 12px 0;

  li {
    padding: 8px 16px;
    margin-top: 1px;

    a,
    button {
      display: block;
      font-size: 15px;
      font-weight: 400;
      text-align: left;
      width: 100%;
    }
  }
  div.BupDrawer-slider {
    @include center;
    padding: 4px 0 8px;

    div {
      background-color: $bg-6;
      border-radius: $corner;
      height: 5px;
      width: 80px;
    }
  }
}
