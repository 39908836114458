section.BupHome-pricing {
  margin-bottom: $padding-y;

  div.BupSelector-card {
    @include clamp(3);
    padding: 16px 24px;
    margin-top: 4px;

    img {
      aspect-ratio: auto;
      background: transparent;
      height: auto;
      margin-bottom: 16px;
    }
    h3.MuiTypography-h3 {
      @include font(26px, 300);
      margin: 32px 0 0;
      padding: 0 24px 5px;
    }
    h4.MuiTypography-h4 {
      @include font(22px, 600);
    }
    span.MuiTypography-p {
      font-weight: 300;
      opacity: 0.7;
    }
    button.BupLink-root {
      color: darken($tertiary, 12%);
      font-size: 14px;
      font-weight: 450;

      &:hover {
        color: darken($tertiary, 4%);
        text-decoration: underline;
      }
    }
    button.BupButton-root {
      margin-top: 24px;
      max-width: 256px;
      width: calc(100% - 64px);
    }
    div.BupSelector-plan {
      align-items: center;
      border: 1px solid $border;
      border-radius: 48px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding: 32px;
      text-align: center;

      h3.MuiTypography-h3 {
        white-space: nowrap;
      }
      img {
        filter: invert(1);
      }
      &.BupSelector-popular {
        $color: lighten($secondary, 8%);
        background:
          linear-gradient(90deg, $tertiary 4%, rgb(66, 133, 244) 0) top/100% 34% no-repeat,
          linear-gradient(90deg, $secondary 50%, rgb(66, 133, 244) 0) top/100% 82% no-repeat,
          linear-gradient(90deg, $secondary 10%, $dark 0) top/100% 100%;
        border-color: rgba($color, 0.48);
        border: none;
        color: black;
        padding: 4px;

        h3.MuiTypography-h3 {
          color: $color;
          padding-top: 12px;
        }
        span.MuiTypography-p {
          opacity: 0.8;
        }
        div.BupSelector-filter {
          background: white;
          border-radius: 46px !important;
          align-items: center;
          border-radius: 48px;
          display: flex;
          flex-direction: column;
          padding: 32px;
          text-align: center;
        }
        div.Bup-popular {
          @include row-center;
          @include font(12px, 500);
          @include background($color);

          border-radius: 16px;
          color: white;
          height: 32px;
          letter-spacing: 0.5px;
          margin: 0 auto;
          margin-top: -50px;
          padding: 6px 32px;
          user-select: none;
          width: fit-content !important;
        }
      }
      div {
        width: 100%;
      }
      small {
        line-height: 8px !important;
      }
    }
    @media only screen and (max-width: $lg) {
      margin-bottom: $padding-y;

      div.BupSelector-plan a {
        margin: 0;
      }
    }
    @media only screen and (max-width: $xs) {
      margin-bottom: 30px;
    }
  }
  main {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: $padding-y * 2 / 3 auto 0;
    max-width: $lg;
    text-align: center;

    @media only screen and (max-width: $xs) {
      margin-top: $padding-y / 3;
    }
    &.BupHome-pricingHealth {
      margin-top: 20px;
    }
  }
  p.Bup-disclaimer {
    @include font(14px);
    margin: 30px auto 0;
    max-width: $md;
    text-align: center;
  }
  @media only screen and (max-width: $lg) {
    div.BupSelector-card {
      aspect-ratio: auto;
      padding: 16px 32px 0;
      margin-bottom: 0;

      div.BupSelector-plan {
        max-width: $xs;
        padding: 16px;
      }
      h3.MuiTypography-h3 {
        @include font(24px);
        white-space: nowrap;
      }
      h4.MuiTypography-h4 {
        @include font(22px, bold);
        white-space: nowrap;
      }
      button {
        margin-bottom: 12px;
      }
    }
    main {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      gap: 0;
    }
    p.Bup-disclaimer {
      display: none;
    }
  }
  @media only screen and (max-width: $md) {
    div.BupSelector-card {
      button {
        width: 225px;
      }
      h3.MuiTypography-h3 {
        @include text($color-6);
      }
    }
    main {
      flex-direction: column;
      align-items: center;
    }
  }
  @media only screen and (max-width: $xs) {
    div.BupSelector-card {
      padding: 0;

      div.BupSelector-plan {
        border-color: $border;
        background: $bg-0;
        border-width: 1.5px;
        border-radius: $corner;
        padding: 32px 24px;

        &.BupSelector-popular {
          border-color: $border;
          border-radius: 8px;
          margin-top: 16px;
          margin-bottom: 16px;

          div.Bup-popular {
            display: none !important;
          }
          div.BupSelector-filter {
            border-radius: 8px !important;
            padding: 32px 24px;
          }
        }
        h3.MuiTypography-h3 {
          margin: 16px 0 0;
        }
        span.MuiTypography-p {
          font-size: 14px;
        }
      }
    }
  }
}
.Bup-development {
  section.BupHome-pricing {
    div.BupSelector-filter {
      background-color: $dark-0 !important;
      color: white;

      button.BupLink-root {
        color: $tertiary;

        &:hover {
          color: lighten($tertiary, 4%);
        }
      }
    }
  }
}
