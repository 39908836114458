main.BupPolicies-root {
  margin-top: 90px;
  position: relative;

  h1.MuiTypography-h1 {
    font-size: 32px;
    font-weight: 500;
    margin-left: -2px;
    transition: all 0.3s ease;
  }
  span a {
    color: $tertiary;

    &:hover {
      text-decoration: underline;
    }
  }
  section {
    position: relative;

    &:first-child {
      border-top: 1px solid $border;
      padding-top: 48px;
      transform: all 0.3s ease;
    }
    span.MuiTypography-p {
      font-size: 15px;
      line-height: 1.5;

      b {
        color: black;
        font-weight: 500;
      }
    }
    div.BupPolicies-content {
      display: grid;
      grid-template-columns: auto 375px;
      gap: 40px;

      @media only screen and (max-width: $lg) {
        grid-template-columns: 1fr;
      }
    }
    div.BupPolicies-group {
      h2.MuiTypography-h2 {
        font-size: 20px;
        font-weight: 500;
        margin: 24px 0 0;
        max-width: auto;
        text-align: left;
      }
      h3.MuiTypography-h3 {
        font-size: 16px;
        font-weight: 500;
      }
      span.MuiTypography-p + ul,
      ul + span.MuiTypography-p {
        margin-top: 32px;
      }
      ul {
        li {
          div.BupItem-title {
            &:not(:has(h3)) {
              margin-bottom: -32px;
            }
          }
          i.BupIcon-root {
            color: inherit;
            font-size: 8px !important;
            font-weight: 600;
            min-width: 20px;
            padding-top: 6px;
            width: 20px;
          }
          span {
            margin-left: 20px;
            display: block;
          }
          @media only screen and (max-width: $sm) {
            div.BupItem-title {
              margin-bottom: 0px;

              &:not(:has(h3)) {
                margin-bottom: -16px;
              }
            }
            i.BupIcon-root {
              display: none;
            }
            span {
              margin-left: 0;
            }
          }
        }
      }
    }
    strong {
      font-weight: 500;
    }
    @import "topbar";
  }
  @import "navbar";
}
