section.BupPricing-compare {
  margin-top: 0;

  div.MuiTableContainer-root {
    border-radius: $corner;
    border: 1px solid $border;
    box-shadow: none;
    margin-top: 75px;
    margin-bottom: 16px;

    td.MuiTableCell-root {
      border-left: 1.5px solid $border;

      i.BupIcon-root {
        color: #aaa;
        opacity: 0.66;
        font-size: 18px;
        font-weight: 300;
        vertical-align: middle;
      }
      &.Bup-check i.BupIcon-root {
        color: $tertiary;
        font-weight: 400;
        opacity: 1;
      }
      b {
        color: black !important;
        font-weight: 500;
      }
    }
    thead.MuiTableHead-root,
    tfoot.MuiTableFooter-root {
      tr.MuiTableRow-root {
        span {
          display: block;
          color: white;
          padding: 1px 16px;
          border-radius: $radius;
          font-size: 13px;
          margin: 0 auto;
          white-space: nowrap;
          width: 80px;
          padding: 2px 16px;
          width: 128px;

          @media only screen and (max-width: $sm) {
            background-color: transparent !important;
            color: black;
            padding: 0;
            width: fit-content;
          }
        }

        td,
        th {
          font-size: 16px;
          font-weight: 500;

          span {
            font-size: 13px;
            font-weight: 400;
          }
          &:nth-child(n + 1) {
            background-color: rgba($border, 0.2);
          }
          &:nth-child(n + 2) span {
            background-color: rgba($secondary, 0.9);
          }
          &:nth-child(n + 3) span {
            background-color: rgba($primary, 0.9);
          }
          &:nth-child(n + 4) span {
            background-color: rgba($dark, 0.9);
          }
          @media only screen and (max-width: $md) {
            font-size: 14px;
          }
          @media only screen and (max-width: $xs) {
            &:nth-child(n + 2) {
              padding: 16px 8px;
              width: 70px;
            }
          }
        }
      }
    }
    tfoot.MuiTableFooter-root {
      border-top: 1px solid $border;
      border-width: 1px;

      td.MuiTableCell-root {
        border: none;
      }

      @media only screen and (max-width: $sm) {
        display: none;
      }
    }

    & + small {
      display: block;
      padding: 0 16px;
      font-size: 12.5px;

      @media only screen and (max-width: $sm) {
        padding: 0 8px;
        text-align: center;
      }
    }
  }
}
.Bup-development {
  section.BupPricing-compare {
    background-color: $dark-0;

    * {
      border-color: $dark-2 !important;
    }

    div.MuiTableContainer-root {
      background-color: $dark-0;

      * {
        background-color: $dark-0 !important;
        border-color: $dark-2;
        color: white;
      }
    }
  }
}
