div.BupPolicies-topbar {
  height: 40px;

  span.Bup-pretitle {
    color: $secondary;
    display: none;
    font-size: 13.5px;
    font-weight: 600;
    text-transform: uppercase;
  }

  &.Bup-sticky .BupPolicies-navbar {
    align-items: center;
    backdrop-filter: blur(2px);
    background-color: rgba($bg-0, 0.8);
    border-bottom: 1px solid rgba($border, 0.5);
    display: flex;
    height: 40px;
    justify-content: space-between;
    left: 0;
    margin-top: 60px;
    padding: 0px 78px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;

    @media only screen and (max-width: $lg) {
      padding: 0 16px !important;
    }
    @media only screen and (max-width: $xs) {
      padding: 0 12px !important;
    }
    h1.MuiTypography-h1 {
      font-size: 20px;
    }
  }
}
