section.BupHome-picture {
  .BupImage-root {
    @include video;
    padding: 0;
  }
  div.BupImage-filter {
    background: linear-gradient(
      to left,
      transparent,
      rgba(black, 0.22),
      rgba(black, 0.36),
      rgba(black, 0.51),
      rgba(black, 0.66)
    ) !important;
    color: white;
    justify-content: center;
    padding: 64px;
    width: 50%;

    h4.MuiTypography-h4 {
      font-size: 30px;
      text-shadow: 1px 1px 1px rgba(black, 0.33);
    }
    span.MuiTypography-p {
      color: #e1e1e1;
      font-size: 16px;
      text-shadow: 1px 1px 1px rgba(black, 0.33);
    }
    button.BupButton-root {
      border-color: #c1c1c1;
      margin-top: 24px;

      &:hover {
        background-color: #dadada;
        color: black;
      }
    }
    @media only screen and (max-width: $xl) {
      padding: 32px;
    }
    @media only screen and (max-width: $lg) {
      h4.MuiTypography-h4 {
        font-size: 24px;
      }
      span.MuiTypography-p {
        font-size: 14px;
      }
    }
    @media only screen and (max-width: $md) {
      padding: 16px;
    }
    @media only screen and (max-width: $sm) {
      background: transparent !important;
      border-top: 1px solid $border;
      color: black;
      padding: 75px 0 32px;
      text-align: center;
      width: 100%;

      h4.MuiTypography-h4 {
        font-weight: 500;
        text-shadow: none;
      }
      span.MuiTypography-p {
        color: #777;
        text-shadow: none;
      }
      button.BupButton-root {
        margin: 32px auto 0;
      }
    }
  }
}
