section.BupHome-banco {
  display: flex;
  flex-direction: column;
  align-content: center;
  gap: 75px;

  div.BupPartner-logo {
    @include row-center;
    background-color: #001c4c;
    height: 120px;

    img {
      background-color: transparent;
      object-fit: contain;
      height: 90px;
    }
    @media only screen and (max-width: $md) {
      height: 100px;
      margin-bottom: 0;

      img {
        height: 75px;
      }
    }
    @media only screen and (max-width: $sm) {
      height: 80px;

      img {
        height: 60px;
      }
    }
  }
  div.BupPartner-banner {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
  }
}
