section.BupHome-gallery {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-bottom: $padding-y !important;
  width: 100vw;

  img.BupImage-root {
    height: 150px;
    transition: transform 0.3s ease-in-out;
    width: auto;
    filter: contrast(1.1) saturate(0.9);
  }
  svg {
    &:first-child {
      margin-top: -40px;
      position: absolute;
      max-width: $screen;
      z-index: 1;
    }
    &:last-child {
      margin-top: -45px;
      position: relative;
      transform: rotate(180deg);
      z-index: 1;
    }
    path.curve {
      fill: $background;
    }
  }
  div {
    display: flex;
    gap: 10px;

    &.gr-0 {
      margin-left: -125px;
    }
    &.gr-1 {
      margin-left: -200px;
    }
    &.gr-2 {
      display: none;
      margin-left: 0px;
    }
    &.gr-3 {
      margin-left: -160px;
    }
  }

  @media only screen and (max-width: $md) {
    div {
      &.gr-2 {
        display: none;
      }
    }
  }
  @media only screen and (max-width: $md) {
    div {
      &.gr-1 {
        display: none;
      }
    }
  }
}
